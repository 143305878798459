import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import About from "../view/website/about/About";
import Admission from "../view/website/admission/Admission";
import Career from "../view/website/career/Career";
import CareerForm from "../view/website/career/CareerForm";
import CareerPage from "../view/website/career-page/CareerPage";
import ContactMessage from "../view/website/contact-message/ContactMessage";
import ContactUs from "../view/website/contact-us/ContactUs";
import Events from "../view/website/events/events";
import EventsForm from "../view/website/events/EventsForm";
import GalleryPage from "../view/website/gallery/GalleryPage";
import HomePage from "../view/website/home-page/HomePage";
import NoticePage from "../view/website/notice-page/NoticePage";
import PrincipalsMessage from "../view/website/principal-message/PrincipalsMessage";
import SchoolSchedule from "../view/website/school-schedul/SchoolSchedule";
import ImageGallery from "../view/website/image-gallery/ImageGallery";
import Notice from "../view/website/notice-page/Notice";
import NoticeForm from "../view/website/notice-page/NoticeForm";
import SchoolFacility from "../view/website/school-facility/SchoolFacility";
import SchoolFacilityForm from "../view/website/school-facility/SchoolFacilityForm";
import SchoolSpeciality from "../view/website/school-speciality/SchoolSpeciality";
import SpecialityForm from "../view/website/school-speciality/SpecialityForm";
import Slider from "../view/website/slider/Slider";
import SliderForm from "../view/website/slider/SliderForm";
import Testimonials from "../view/website/testimonial/Testimonials";
import TestimonialForm from "../view/website/testimonial/TestimonialForm";
import TeacherPage from "../view/website/teacher-page/TeacherPage";
import SchoolPolicyPage from "../view/website/school-policy-page/SchoolPolicyPage";
import RulesRegulationPage from "../view/website/rules-regulation-page/RulesRegulationPage";
import CurriculumPage from "../view/website/curriculum-page/CurriculumPage";
import ClassRoutinePage from "../view/website/class-routine-page/ClassRoutinePage";
import AcademicCalendarPage from "../view/website/academic-calendar/AcademicCalendarPage";
import EventsPage from "../view/website/events/EventsPage";

export default function WebsiteRoutes() {
  return (
    <Routes>
      <Route
        path="/pages/about"
        element={<ProtectedRoutes Component={About} />}
      />
      <Route
        path="/pages/admission"
        element={<ProtectedRoutes Component={Admission} />}
      />
      {/*  */}
      <Route
        path="/components/careers"
        element={<ProtectedRoutes Component={Career} />}
      />
      <Route
        path="/careers/add-career"
        element={<ProtectedRoutes Component={CareerForm} />}
      />
      <Route
        path="/components/careers/:id"
        element={<ProtectedRoutes Component={CareerForm} />}
      />
      {/*  */}
      <Route
        path="/pages/career"
        element={<ProtectedRoutes Component={CareerPage} />}
      />
      {/*  */}
      <Route
        path="/components/contact-us-messages"
        element={<ProtectedRoutes Component={ContactMessage} />}
      />
      {/*  */}
      <Route
        path="/pages/contact-us"
        element={<ProtectedRoutes Component={ContactUs} />}
      />
      {/*  */}
      <Route
        path="/components/events"
        element={<ProtectedRoutes Component={Events} />}
      />
      <Route
        path="/components/events/add-event"
        element={<ProtectedRoutes Component={EventsForm} />}
      />
      <Route
        path="/components/events/:id"
        element={<ProtectedRoutes Component={EventsForm} />}
      />
      {/*  */}
      <Route
        path="/pages/gallery"
        element={<ProtectedRoutes Component={GalleryPage} />}
      />
      {/*  */}
      <Route
        path="/pages/home"
        element={<ProtectedRoutes Component={HomePage} />}
      />
      {/*  */}
      <Route
        path="/pages/notice"
        element={<ProtectedRoutes Component={NoticePage} />}
      />
      {/*  */}
      <Route
        path="/pages/principals-message"
        element={<ProtectedRoutes Component={PrincipalsMessage} />}
      />
      {/*  */}
      <Route
        path="/components/school-schedules"
        element={<ProtectedRoutes Component={SchoolSchedule} />}
      />
      {/*  */}
      <Route
        path="/components/image-gallery"
        element={<ProtectedRoutes Component={ImageGallery} />}
      />
      {/*  */}
      <Route
        path="/components/notices"
        element={<ProtectedRoutes Component={Notice} />}
      />
      <Route
        path="/components/notices/add-notice"
        element={<ProtectedRoutes Component={NoticeForm} />}
      />
      <Route
        path="/components/notices/:id"
        element={<ProtectedRoutes Component={NoticeForm} />}
      />
      {/*  */}
      <Route
        path="/components/school-facilities"
        element={<ProtectedRoutes Component={SchoolFacility} />}
      />
      <Route
        path="/components/school-facilities/add-facility"
        element={<ProtectedRoutes Component={SchoolFacilityForm} />}
      />
      <Route
        path="/components/school-facilities/:id"
        element={<ProtectedRoutes Component={SchoolFacilityForm} />}
      />
      {/*  */}
      <Route
        path="/components/school-specialities"
        element={<ProtectedRoutes Component={SchoolSpeciality} />}
      />
      <Route
        path="/components/school-specialities/add-speciality"
        element={<ProtectedRoutes Component={SpecialityForm} />}
      />
      <Route
        path="/components/school-specialities/:id"
        element={<ProtectedRoutes Component={SpecialityForm} />}
      />
      {/*  */}
      <Route
        path="/components/sliders"
        element={<ProtectedRoutes Component={Slider} />}
      />
      <Route
        path="/components/sliders/add-new"
        element={<ProtectedRoutes Component={SliderForm} />}
      />
      <Route
        path="/components/sliders/:id"
        element={<ProtectedRoutes Component={SliderForm} />}
      />
      {/*  */}
      <Route
        path="/components/testimonials"
        element={<ProtectedRoutes Component={Testimonials} />}
      />
      <Route
        path="/components/testimonials/add-new"
        element={<ProtectedRoutes Component={TestimonialForm} />}
      />
      <Route
        path="/components/testimonials/:id"
        element={<ProtectedRoutes Component={TestimonialForm} />}
      />
      {/*  */}
      <Route
        path="/pages/teachers"
        element={<ProtectedRoutes Component={TeacherPage} />}
      />
      {/*  */}
      <Route
        path="/pages/schools-policy"
        element={<ProtectedRoutes Component={SchoolPolicyPage} />}
      />
      {/*  */}
      <Route
        path="/pages/rules-regulations"
        element={<ProtectedRoutes Component={RulesRegulationPage} />}
      />
      {/*  */}
      <Route
        path="/pages/curriculums"
        element={<ProtectedRoutes Component={CurriculumPage} />}
      />
      {/*  */}
      <Route
        path="/pages/class-routine"
        element={<ProtectedRoutes Component={ClassRoutinePage} />}
      />
      {/*  */}
      <Route
        path="/pages/academic-calendar"
        element={<ProtectedRoutes Component={AcademicCalendarPage} />}
      />
      {/*  */}
      <Route
        path="/pages/events"
        element={<ProtectedRoutes Component={EventsPage} />}
      />
    </Routes>
  );
}
