import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchSingleTimeSlot,
  postTimeSlot,
  putTimeSlot,
} from "../../../libs/redux/features/academic/timeSlotSlice";
import { Breadcrumb, Button, Checkbox, Col, Row, Spin, TimePicker } from "antd";
import dayjs from "dayjs";
import usePageTitle from "../../../components/PageTitle";

const TimeSlotsForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleData, isLoading, error } = useSelector(
    (state) => state.timeSlots
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleTimeSlot(id));
    }
  }, [id, dispatch]);

  usePageTitle(id ? "TIme Slot Detail" : "Add Time Slot");

  const [timeSlot, setTimeSlot] = useState({
    start_time: "",
    end_time: "",
    is_break: false,
  });

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setTimeSlot({
        start_time: singleData.start_time,
        end_time: singleData.end_time,
        is_break: singleData.is_break,
      });
    }
  }, [id, singleData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = timeSlot;
    if (id) {
      dispatch(putTimeSlot({ id: id, payload: payload }));
    } else {
      dispatch(
        postTimeSlot({
          payload,
          onSuccess: () => {
            setTimeSlot({
              start_time: "",
              end_time: "",
              is_break: false,
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? "Time Slot Detail" : "Add Time Slot"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Academic",
              },
              {
                title: <Link to="/academic/time-slots">Time Slots</Link>,
              },
              {
                title: id ? "Time Slot Detail" : "Add Designation",
              },
            ]}
          />
        </div>
      </div>

      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Time Slot Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Start Time</p>
                        {error && error.start_time ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.start_time})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <TimePicker
                        className="w-100"
                        size="large"
                        placeholder="Start Time"
                        required
                        value={
                          timeSlot.start_time
                            ? dayjs(timeSlot.start_time, "HH:mm:ss")
                            : ""
                        }
                        onChange={(value) =>
                          setTimeSlot((prev) => ({
                            ...prev,
                            start_time: value
                              ? dayjs(value).format("HH:mm:ss")
                              : null,
                          }))
                        }
                        status={error && error.start_time ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* End Time</p>
                        {error && error.end_time ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.end_time})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <TimePicker
                        className="w-100"
                        size="large"
                        placeholder="Start Time"
                        required
                        value={
                          timeSlot.end_time
                            ? dayjs(timeSlot.end_time, "HH:mm:ss")
                            : ""
                        }
                        onChange={(value) =>
                          setTimeSlot((prev) => ({
                            ...prev,
                            end_time: value
                              ? dayjs(value).format("HH:mm:ss")
                              : null,
                          }))
                        }
                        status={error && error.end_time ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={timeSlot.is_break}
                        onChange={(e) =>
                          setTimeSlot((prev) => ({
                            ...prev,
                            is_break: e.target.checked,
                          }))
                        }
                      >
                        Is Break ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default TimeSlotsForm;
