import {
  Breadcrumb,
  Button,
  Image,
  Pagination,
  Switch,
  Table,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchFacilities } from "../../../libs/redux/features/website/facilitieSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
  renderDescription,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const SchoolFacility = () => {
  usePageTitle("School Facilities");
  const dispatch = useDispatch();
  const { isLoading, data, totalItems } = useSelector(
    (state) => state.facilities
  );

  const [isActive, setIsActive] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const offset = (currentPage - 1) * pageSize;
  useEffect(() => {
    dispatch(fetchFacilities({ is_active: isActive, limit: pageSize, offset }));
  }, [dispatch, pageSize, offset, isActive]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      fixed: "left",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (image) => (
        <div>
          <Image src={image.image} alt={image.alt_text} width={50} />
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => renderDescription(text),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `website/schoolfacility/${record.id}/`,
              fetchFacilities
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/website/components/school-facilities/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `website/schoolfacility/${record.id}/`,
                fetchFacilities
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleFilterChange = (value) => {
    setIsActive(value);
    setCurrentPage(1);
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Title: item.title || "N/A",
    // Image: item.image.image || null,
    Lists: item.list_items || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Title", "Lists", "Status"];
    downloadPDF(data, columns, formatTimeSlotsData, "School Facilities Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "School Facilities Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between p-4">
        <div>
          <h1 className="fs-2 text-dark">School Facilities</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Components" },
              { title: "School Facilities" },
            ]}
          />
        </div>
        <div>
          <Link to="/website/components/school-facilities/add-facility">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              defaultValue={undefined}
              className="w-200"
              onChange={handleFilterChange}
              placeholder="Filter by Status"
            >
              <Select.Option value={undefined}>All</Select.Option>
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>Inactive</Select.Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data && data.length > 0 ? data : []}
          rowKey={(record) => record.id}
          pagination={false}
          loading={isLoading}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (currentPage - 1) * pageSize + 1;
            const endItem = Math.min(currentPage * pageSize, totalItems);

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={handlePageChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default SchoolFacility;
