import {
  Breadcrumb,
  Col,
  Input,
  Row,
  Spin,
  DatePicker,
  Checkbox,
  Button,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import DocumentPopup from "../../../components/DocumentPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleNotice,
  postNotice,
  putNotice,
} from "../../../libs/redux/features/website/noticesSlice";
import { getDocIcon } from "../../../components/utility";
import dayjs from "dayjs";
import usePageTitle from "../../../components/PageTitle";

const NoticeForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleNotice, isLoading, error } = useSelector(
    (state) => state.notices
  );

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSingleNotice(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (
      !isNaN(id) &&
      Object.keys(singleNotice).length > 0 &&
      singleNotice.seo
    ) {
      setNotice(singleNotice);
    }
  }, [id, singleNotice]);
  usePageTitle(!isNaN(id) ? `${singleNotice.title}` : "Add Notice");

  const [notice, setNotice] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    slug: "",
    title: "",
    date: "",
    description: "",
    is_active: true,
    attachment: [],
  });

  const [docModal, setDocModal] = useState(false);

  const handleDocs = (data) => {
    setNotice((prev) => ({
      ...prev,
      attachment: [...prev.attachment, ...data],
    }));
    setDocModal(false);
  };

  const handleClose = (removedItem) => {
    setNotice((prev) => ({
      ...prev,
      attachment: prev.attachment.filter((item) => item.id !== removedItem.id),
    }));
  };

  const handleNoticeSubmit = async (e) => {
    e.preventDefault();
    if (!isNaN(id)) {
      const payload = {
        ...notice,
        attachment: notice.attachment.map((item) => item.id),
      };
      dispatch(putNotice({ id: id, payload: payload }));
    } else {
      let payload = {
        ...notice,
        attachment:
          notice.attachment.length > 0
            ? notice.attachment.map((item) => item.id)
            : [],
      };

      dispatch(
        postNotice({
          payload,
          onSuccess: () => {
            setNotice({
              seo: {
                meta_title: "",
                meta_description: "",
                meta_keywords: "",
              },
              slug: "",
              title: "",
              date: "",
              description: "",
              is_active: true,
              attachment: [],
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <div className="breadcrumb p-4">
        <div>
          <h1 className="fs-2 text-dark">
            {!isNaN(id) ? `${singleNotice.title}` : "Add Notice"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Website",
              },
              {
                title: "Components",
              },
              {
                title: <Link to="/website/components/notices">Notices</Link>,
              },
              {
                title: !isNaN(id) ? `${singleNotice.title}` : "Add Notice",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleNoticeSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={notice.seo.meta_title || ""}
                        onChange={(e) =>
                          setNotice((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={notice.seo.meta_description || ""}
                        onChange={(e) =>
                          setNotice((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        Meta Keywords (comma separated)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={notice.seo.meta_keywords || ""}
                        onChange={(e) =>
                          setNotice((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Slug</p>
                        {error && error.slug ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.slug})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Slug"
                        value={notice.slug}
                        onChange={(e) =>
                          setNotice((prev) => ({
                            ...prev,
                            slug: e.target.value,
                          }))
                        }
                        required
                        status={error && error.slug ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Title"
                        value={notice.title}
                        onChange={(e) =>
                          setNotice((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Date</p>
                      <DatePicker
                        size="large"
                        className="w-100 rounded-10"
                        onChange={(e) =>
                          setNotice((prev) => ({
                            ...prev,
                            date: e ? dayjs(e).format("YYYY-MM-DD") : null,
                          }))
                        }
                        format="YYYY-MM-DD"
                        value={
                          notice.date ? dayjs(notice.date, "YYYY-MM-DD") : null
                        }
                        placeholder="Select Date"
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-5">
                      <p className="mb-2 ps-2 fw-6">* Description</p>
                      <Editor
                        apiKey="58fdmypbltmy8x4mc23zkbd4uzw5feyqvv8kox1fsj41wf7f"
                        value={notice.description}
                        onEditorChange={(value) =>
                          setNotice((prev) => ({
                            ...prev,
                            description: value,
                          }))
                        }
                        init={{
                          height: 500,
                          plugins: ["lists"],
                          toolbar: `undo redo | formatselect | bold italic forecolor backcolor | 
                          fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | 
                          bullist numlist outdent indent | removeformat | help`,
                          fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt", // Customize font sizes
                          content_style:
                            "body { font-family: 'Cabin', sans-serif; }",
                        }}
                      />
                    </div>
                  </Col>

                  <Col span={24} className="mb-3">
                    <p className="mb-2">* Attachments</p>
                    <div className="flex aic">
                      <Button
                        onClick={() => setDocModal(true)}
                        className={`flex justify-center aic rounded-10 ${
                          error && error.attachment
                            ? "border-dashed-double"
                            : "border-dashed"
                        }`}
                        style={{ height: 100, width: 100 }}
                        danger={error && error.attachment ? true : false}
                      >
                        + Upload
                      </Button>
                      <div className="ms-3">
                        {notice.attachment && notice.attachment.length > 0 ? (
                          notice.attachment.map((item) => {
                            return (
                              <Tag
                                key={item.id}
                                closable
                                onClose={() => handleClose(item)}
                                icon={getDocIcon(item.doc_type)}
                                className="m-1 p-2 flex aic"
                                color="success"
                              >
                                {item.title}
                              </Tag>
                            );
                          })
                        ) : error && error.attachment ? (
                          <p className="text-danger">{error.attachment}</p>
                        ) : (
                          "No file selected"
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <Checkbox
                        checked={notice.is_active}
                        onChange={(e) =>
                          setNotice((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      // loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
      <DocumentPopup
        open={docModal}
        onCancel={() => setDocModal(false)}
        onUploadSuccess={handleDocs}
        docDescription={notice.description}
      />
    </div>
  );
};

export default NoticeForm;
