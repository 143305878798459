import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch courses with optional filters (class_level, is_active, name, limit, offset)
export const fetchCourses = createAsyncThunk(
  "courses/fetchCourses",
  async (
    {
      class_level = "",
      is_active = "",
      name = "",
      limit = 10,
      offset = 0,
    } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (class_level !== "") params.class_level = class_level;
      if (is_active !== "") params.is_active = is_active;
      if (name !== "") params.name = name;

      const response = await getData("academic/admin/courses/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single course by ID
export const fetchSingleCourse = createAsyncThunk(
  "courses/fetchSingleCourse",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`academic/admin/courses/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new course
export const postCourse = createAsyncThunk(
  "courses/postCourse",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("academic/admin/courses/", payload);
      if (response.status === 200 || response.status === 201) {
        message.success("Course created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to create course.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing course by ID
export const putCourse = createAsyncThunk(
  "courses/putCourse",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`academic/admin/courses/${id}/`, payload);
      if (response.status === 200 || response.status === 201) {
        message.success("Course updated successfully!");
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.name?.[0] ||
          error.response.statusText ||
          "Failed to update course.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Courses slice
const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchCourses cases
      .addCase(fetchCourses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })

      // Handle fetchSingleCourse cases
      .addCase(fetchSingleCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle postCourse cases
      .addCase(postCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCourse.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle putCourse cases
      .addCase(putCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putCourse.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putCourse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default coursesSlice.reducer;
