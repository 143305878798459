import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  Input,
  Select,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchCareer } from "../../../libs/redux/features/website/careerSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { Search } = Input;
const { Option } = Select;

const Career = () => {
  usePageTitle("Careers");
  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector((state) => state.career);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("all");

  const offset = (currentPage - 1) * pageSize;

  useEffect(() => {
    dispatch(
      fetchCareer({
        limit: pageSize,
        offset,
        search: searchText,
        is_active: statusFilter === "all" ? undefined : statusFilter,
      })
    );
  }, [dispatch, searchText, statusFilter, pageSize, offset]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleStatusChangeDropdown = (value) => {
    setStatusFilter(value);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 30,
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      width: 150,
      fixed: "left",
    },
    {
      title: "Job Type",
      dataIndex: "job_type",
      width: 100,
    },
    {
      title: "Experience",
      dataIndex: "experience_required",
      width: 100,
    },
    {
      title: "Vacancy",
      dataIndex: "vacancy",
      width: 100,
    },
    {
      title: "Salary",
      dataIndex: "salary",
      width: 100,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      width: 100,
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `website/career/${record.id}/`,
              fetchCareer
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/website/components/careers/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.job_title}`,
                dispatch,
                `/website/career/${record.id}/`,
                fetchCareer
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    "Job Title": item.job_title || "N/A",
    "Job Type": item.job_type || "N/A",
    Date: item.date_posted || "N/A",
    "Experience Required": item.experience_required || "N/A",
    "Working Hour": item.working_hours || "N/A",
    "Working Days": item.working_days || "N/A",
    Salary: item.salary || "N/A",
    Vacancy: item.vacancy || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Job Title",
      "Job Type",
      "Date",
      "Experience Required",
      "Working Hour",
      "Working Days",
      "Salary",
      "Vacancy",
      "Status",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Careers Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Careers Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between p-4">
        <div>
          <h1 className="fs-2 text-dark">Careers</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Components" },
              { title: "Careers" },
            ]}
          />
        </div>
        <div>
          <Link to="/website/components/careers/add-career">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by Job Title"
              onSearch={handleSearch}
              className="w-200"
              allowClear
            />
            <Select
              defaultValue="all"
              className="w-200"
              onChange={handleStatusChangeDropdown}
            >
              <Option value="all">All</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (currentPage - 1) * pageSize + 1;
            const endItem = Math.min(currentPage * pageSize, totalItems);

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={handlePaginationChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Career;
