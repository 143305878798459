import { Card, Col, Row } from "antd";
import React from "react";
// import Chart from "../../components/Chart";
// import { attendanceData } from "../../assets/data/sidebarMenus";

const Homepage = () => {
  return (
    <div>
      <div className="mt-5">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Card title="Visitor Count" bordered={false} className="mb-2">
              000
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Card title="Recent Visitors" bordered={false} className="mb-2">
              00
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Card title="Card title" bordered={false} className="mb-2">
              Card content
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Card title="Card title" bordered={false} className="mb-2">
              Card content
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        {/* <Row gutter={12}>
          <Col md={24} lg={12}>
            <Chart
              data={attendanceData}
              xField="Month" // Corrected key for x-axis
              yField="Attendance" // Corrected key for y-axis
              barDataKey="Attendance" // Ensure this matches a key in data
            />
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default Homepage;
