import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

export const fetchNotices = createAsyncThunk(
  "notices/fetchNotices",
  async (
    { start_date, end_date, is_active, limit = 10, offset = 0, search } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (start_date) params.start_date = start_date;
      if (end_date) params.end_date = end_date;
      if (is_active !== undefined) params.is_active = is_active;
      if (search) params.search = search;

      const response = await getData(`website/notice/`, params);

      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postNotice = createAsyncThunk(
  "notices/postNotice",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("website/notice/", payload);
      if (response.status === 200 || 201) {
        message.success("Notice item created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.slug?.[0] || error.response.data.attachment?.[0];

        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSingleNotice = createAsyncThunk(
  "notices/fetchSingleNotice",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`website/notice/${id}/`);

      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error(error.response.statusText);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const putNotice = createAsyncThunk(
  "notices/putNotice",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`website/notice/${id}/`, payload);
      if (response.status === 200 || 201) {
        message.success("Notice item updated successfully!");
        fetchSingleNotice(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error(error.response.statusText);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const noticesSlice = createSlice({
  name: "notices",
  initialState: {
    data: [],
    isLoading: false,
    totalItems: 0,
    offset: 0,
    limit: 32,
    error: null,
    singleNotice: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchNotices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.data = [];
      })
      .addCase(postNotice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postNotice.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postNotice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleNotice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleNotice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleNotice = action.payload;
      })
      .addCase(fetchSingleNotice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putNotice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putNotice.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(putNotice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default noticesSlice.reducer;
