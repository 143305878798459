import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch all sliders
export const fetchSliders = createAsyncThunk(
  "sliders/fetchSliders",
  async ({ is_active, limit = 10, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (is_active !== undefined) params.is_active = is_active;

      const response = await getData(`website/slider/`, params);

      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new slider
export const postSlider = createAsyncThunk(
  "sliders/postSlider",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData(`website/slider/`, payload);
      if (response.status === 200 || 201) {
        message.success("Slider item created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error("Failed to create slider item.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single slider by ID
export const fetchSingleSlider = createAsyncThunk(
  "sliders/fetchSingleSlider",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`website/slider/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a slider by ID
export const putSlider = createAsyncThunk(
  "sliders/putSlider",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`website/slider/${id}/`, payload);
      if (response.status === 200 || 201) {
        message.success("Slider updated successfully!");
        fetchSingleSlider(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error(error.response.statusText);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slider slice
const sliderSlice = createSlice({
  name: "sliders",
  initialState: {
    data: [],
    isLoading: false,
    totalItems: 0,
    offset: 0,
    limit: 10,
    error: null,
    singleSlider: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSliders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchSliders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.data = [];
      })
      .addCase(postSlider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postSlider.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postSlider.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleSlider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleSlider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleSlider = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleSlider.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.singleSlider = {};
      })
      .addCase(putSlider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putSlider.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(putSlider.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default sliderSlice.reducer;
