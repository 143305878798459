import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch class routines with filters
export const fetchClassRoutines = createAsyncThunk(
  "routines/fetchClassRoutines",
  async (
    {
      class_info = "",
      course = "",
      teacher = "",
      time_slot = "",
      weekday = "",
      limit = 10,
      offset = 0,
    } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        class_info,
        course,
        teacher,
        time_slot,
        weekday,
      };

      const response = await getData("academic/admin/class-routines/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single class routine by ID
export const fetchSingleClassRoutine = createAsyncThunk(
  "routines/fetchSingleClassRoutine",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`academic/admin/class-routines/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new class routine
export const postClassRoutine = createAsyncThunk(
  "routines/postClassRoutine",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      console.log("payload routine", payload);

      const response = await postData(
        "academic/admin/class-routines/",
        payload
      );
      console.log("post routine", response);

      if (response.status === 200 || 201) {
        message.success("Class routine created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      console.log("post routine", error.response);

      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.detail ||
          error.response.data?.non_field_errors?.[0] ||
          error.response.statusText ||
          "Failed to post class routine.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing class routine by ID
export const putClassRoutine = createAsyncThunk(
  "routines/putClassRoutine",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(
        `academic/admin/class-routines/${id}/`,
        payload
      );
      if (response.status === 200 || 201) {
        message.success("Class routine updated successfully!");
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.detail ||
          error.response.statusText ||
          "Failed to update class routine.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Class routines slice
const routinesSlice = createSlice({
  name: "routines",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassRoutines.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClassRoutines.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchClassRoutines.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleClassRoutine.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleClassRoutine.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleClassRoutine.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postClassRoutine.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postClassRoutine.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postClassRoutine.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putClassRoutine.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putClassRoutine.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putClassRoutine.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default routinesSlice.reducer;
