import React from "react";
import { Route, Routes } from "react-router-dom";
import Signin from "../view/auth/Signin";
import MainLayout from "../view/MainLayout";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Profile from "../view/profile/Profile";
import StudentsRoutes from "./studentsRoutes";
import AcademicRoutes from "./academicRoutes";
import WebsiteRoutes from "./websiteRoutes";
import GalleryPage from "../view/Test";
import Homepage from "../view/home-page/Homepage";
import TeacherRoutes from "./teacherRoutes";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/sign-in" element={<Signin />} />
      <Route path="/" element={<ProtectedRoutes Component={MainLayout} />}>
        <Route index element={<ProtectedRoutes Component={Homepage} />} />

        <Route
          path="profile"
          element={<ProtectedRoutes Component={Profile} />}
        />
        {/* students */}
        <Route path="student-data/*" element={<StudentsRoutes />} />
        {/* academic */}
        <Route path="academic/*" element={<AcademicRoutes />} />
        {/* teacher */}
        <Route path="teachers-info/*" element={<TeacherRoutes />} />
        {/* website */}
        <Route path="website/*" element={<WebsiteRoutes />} />
        <Route path="/test" element={<GalleryPage />} />
      </Route>
    </Routes>
  );
}
