import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import AcademicYear from "../view/academic/academic-year/AcademicYear";
import AcademicYearForm from "../view/academic/academic-year/AcademicYearForm";
import Classes from "../view/academic/classes/Classes";
import ClassesForm from "../view/academic/classes/ClassesForm";
import Sections from "../view/academic/sections/Sections";
import TimeSlots from "../view/academic/time-slots/TimeSlots";
import TimeSlotsForm from "../view/academic/time-slots/TimeSlotsForm";
import Courses from "../view/academic/courses/Courses";
import ClassRoutines from "../view/academic/class-routines/ClassRoutines";
import CoursesForm from "../view/academic/courses/CoursesForm";
import ClassRoutineForm from "../view/academic/class-routines/ClassRoutineForm";
import Calendar from "../view/academic/academic-calendar/Calendar";
import CalendarForm from "../view/academic/academic-calendar/CalendarForm";

export default function AcademicRoutes() {
  return (
    <Routes>
      <Route
        path="/academic-years"
        element={<ProtectedRoutes Component={AcademicYear} />}
      />
      <Route
        path="/academic-years/add-new"
        element={<ProtectedRoutes Component={AcademicYearForm} />}
      />
      <Route
        path="/academic-years/:id"
        element={<ProtectedRoutes Component={AcademicYearForm} />}
      />
      {/* classes */}
      <Route
        path="/classes"
        element={<ProtectedRoutes Component={Classes} />}
      />
      <Route
        path="/classes/add-class"
        element={<ProtectedRoutes Component={ClassesForm} />}
      />
      <Route
        path="/classes/:id"
        element={<ProtectedRoutes Component={ClassesForm} />}
      />
      {/* sections */}
      <Route
        path="/sections"
        element={<ProtectedRoutes Component={Sections} />}
      />
      {/* time slots */}
      <Route
        path="/time-slots"
        element={<ProtectedRoutes Component={TimeSlots} />}
      />
      <Route
        path="/time-slots/add-time-slot"
        element={<ProtectedRoutes Component={TimeSlotsForm} />}
      />
      <Route
        path="/time-slots/:id"
        element={<ProtectedRoutes Component={TimeSlotsForm} />}
      />
      {/* Courses */}
      <Route
        path="/courses"
        element={<ProtectedRoutes Component={Courses} />}
      />
      <Route
        path="/courses/add-course"
        element={<ProtectedRoutes Component={CoursesForm} />}
      />
      <Route
        path="/courses/:id"
        element={<ProtectedRoutes Component={CoursesForm} />}
      />
      {/* class routines */}
      <Route
        path="/class-routines"
        element={<ProtectedRoutes Component={ClassRoutines} />}
      />
      <Route
        path="/class-routines/add-class-routine"
        element={<ProtectedRoutes Component={ClassRoutineForm} />}
      />
      <Route
        path="/class-routines/:id"
        element={<ProtectedRoutes Component={ClassRoutineForm} />}
      />
      {/* calendar */}
      <Route
        path="/calendars"
        element={<ProtectedRoutes Component={Calendar} />}
      />
      <Route
        path="/calendars/add-calendar"
        element={<ProtectedRoutes Component={CalendarForm} />}
      />
      <Route
        path="/calendars/:id"
        element={<ProtectedRoutes Component={CalendarForm} />}
      />
    </Routes>
  );
}
