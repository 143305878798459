import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Department from "../view/teachers/department/Department";
import DepartmentForm from "../view/teachers/department/DepartmentForm";
import Designation from "../view/teachers/designation/Designation";
import DesignationForm from "../view/teachers/designation/DesignationForm";
import Teachers from "../view/teachers/teacher/Teachers";
import TeacherForm from "../view/teachers/teacher/TeacherForm";

const TeacherRoutes = () => {
  return (
    <Routes>
      {/* departments */}
      <Route
        path="/departments"
        element={<ProtectedRoutes Component={Department} />}
      />
      <Route
        path="/departments/add-department"
        element={<ProtectedRoutes Component={DepartmentForm} />}
      />
      <Route
        path="/departments/:id"
        element={<ProtectedRoutes Component={DepartmentForm} />}
      />
      {/* designations */}
      <Route
        path="/designations"
        element={<ProtectedRoutes Component={Designation} />}
      />
      <Route
        path="/designations/add-designation"
        element={<ProtectedRoutes Component={DesignationForm} />}
      />
      <Route
        path="/designations/:id"
        element={<ProtectedRoutes Component={DesignationForm} />}
      />
      {/* teachers */}
      <Route
        path="/teachers"
        element={<ProtectedRoutes Component={Teachers} />}
      />
      <Route
        path="/teachers/add-teacher"
        element={<ProtectedRoutes Component={TeacherForm} />}
      />
      <Route
        path="/teachers/:id"
        element={<ProtectedRoutes Component={TeacherForm} />}
      />
    </Routes>
  );
};

export default TeacherRoutes;
