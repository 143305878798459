import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

export const fetchCareer = createAsyncThunk(
  "career/fetchCareer",
  async (
    { search = "", limit = 10, offset = 0, is_active } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
        search,
      };

      if (is_active !== undefined) {
        params.is_active = is_active;
      }

      const response = await getData("website/career/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSingleCareerItem = createAsyncThunk(
  "career/fetchSingleCareer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`website/career/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCareer = createAsyncThunk(
  "career/postCareer",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("website/career/", payload);
      if (response.status === 200 || 201) {
        message.success("Career item created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.slug?.[0] ||
          error.response.data.job_type?.[0] ||
          error.response.data.experience_required?.[0] ||
          error.response.data.working_hours?.[0] ||
          error.response.data.salary?.[0] ||
          error.response.data.vacancy?.[0] ||
          error.response.statusText ||
          "Failed to post career item.";
        message.error(errorMessage || "Failed to create career item.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const putCareer = createAsyncThunk(
  "career/putCareer",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`website/career/${id}/`, payload);

      if (response.status === 200 || 201) {
        message.success("Career item updated successfully!");
        fetchSingleCareerItem(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorData = error.response.data;
        message.error(
          errorData.slug?.[0] ||
            error.response.statusText ||
            "Failed to update career item."
        );
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const careerSlice = createSlice({
  name: "career",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCareer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCareer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchCareer.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleCareerItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleCareerItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleCareerItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(postCareer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCareer.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postCareer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putCareer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putCareer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putCareer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default careerSlice.reducer;
