import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/authentication/authSlice";
import countrySlice from "../features/authentication/countrySlice";
import profileSlice from "../features/profile/profileSlice";
import academicYearSlice from "../features/academic/academicYearSlice";
import gallerySlice from "../features/website/gallerySlice";
import careerSlice from "../features/website/careerSlice";
import eventSlice from "../features/website/eventSlice";
import noticesSlice from "../features/website/noticesSlice";
import facilitieSlice from "../features/website/facilitieSlice";
import specialitySlice from "../features/website/specialitySlice";
import sliderSlice from "../features/website/sliderSlice";
import testimonialSlice from "../features/website/testimonialSlice";
import classSlice from "../features/academic/classSlice";
import departmentsSlice from "../features/teacher-info/departmentsSlice";
import designationsSlice from "../features/teacher-info/designationsSlice";
import teachersSlice from "../features/teacher-info/teachersSlice";
import timeSlotSlice from "../features/academic/timeSlotSlice";
import coursesSlice from "../features/academic/coursesSlice";
import routinesSlice from "../features/academic/routinesSlice";
import calendarSlice from "../features/academic/calendarSlice";
import sectionSlice from "../features/academic/sectionSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    country: countrySlice,
    profile: profileSlice,
    academicYear: academicYearSlice,
    gallery: gallerySlice,
    career: careerSlice,
    events: eventSlice,
    notices: noticesSlice,
    facilities: facilitieSlice,
    specialities: specialitySlice,
    sliders: sliderSlice,
    testimonials: testimonialSlice,
    classes: classSlice,
    departments: departmentsSlice,
    designations: designationsSlice,
    teachers: teachersSlice,
    timeSlots: timeSlotSlice,
    courses: coursesSlice,
    routines: routinesSlice,
    calendar: calendarSlice,
    sections: sectionSlice,
  },
});

export default store;
