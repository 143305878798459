import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimeSlots } from "../../../libs/redux/features/academic/timeSlotSlice";
import {
  handleDelete,
  handleSwitchChange,
  downloadPDF,
  downloadXLS,
} from "../../../components/utility";
import {
  Breadcrumb,
  Button,
  TimePicker,
  Select,
  Table,
  Pagination,
  Switch,
} from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { Option } = Select;

const TimeSlots = () => {
  usePageTitle("Time Slots");

  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector(
    (state) => state.timeSlots
  );

  const [filters, setFilters] = useState({
    start_time: null,
    end_time: null,
    currentPage: 1,
    pageSize: 10,
    breakStatus: "all",
  });

  const offset = (filters.currentPage - 1) * filters.pageSize;

  useEffect(() => {
    dispatch(
      fetchTimeSlots({
        limit: filters.pageSize,
        offset,
        start_time:
          filters.start_time !== null ? filters.start_time : undefined,
        end_time: filters.end_time !== null ? filters.end_time : undefined,
        is_break:
          filters.breakStatus === "all"
            ? undefined
            : filters.breakStatus === true,
      })
    );
  }, [filters, offset, dispatch]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: key === "breakStatus" && value !== "all" ? value === true : value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 45,
      fixed: "left",
      render: (text, record, index) =>
        (filters.currentPage - 1) * filters.pageSize + index + 1,
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
    },
    {
      title: "Is Break",
      dataIndex: "is_break",
      render: (is_break, record) => (
        <Switch
          checked={is_break}
          onChange={(checked) =>
            handleSwitchChange(
              "is_break",
              checked,
              record,
              dispatch,
              `academic/admin/time-slots/${record.id}/`,
              fetchTimeSlots
            )
          }
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/time-slots/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.start_time} - ${record.end_time}`,
                dispatch,
                `academic/admin/time-slots/${record.id}/`,
                fetchTimeSlots
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    "Start Time": item.start_time || "N/A",
    "End Time": item.end_time || "N/A",
    "Is Break": item.is_break ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Start Time", "End Time", "Is Break"];
    downloadPDF(data, columns, formatTimeSlotsData, "TimeSlots Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "TimeSlots Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Time Slots</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Academic" },
              { title: "Time Slots" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/time-slots/add-time-slot">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <TimePicker
              className="w-200"
              onChange={(value) =>
                handleFilterChange(
                  "start_time",
                  value ? dayjs(value).format("HH:mm:ss") : null
                )
              }
              value={
                filters.start_time
                  ? dayjs(filters.start_time, "HH:mm:ss")
                  : null
              }
              placeholder="Start Time"
            />
            <TimePicker
              className="w-200"
              onChange={(value) =>
                handleFilterChange(
                  "end_time",
                  value ? dayjs(value).format("HH:mm:ss") : null
                )
              }
              value={
                filters.end_time ? dayjs(filters.end_time, "HH:mm:ss") : null
              }
              placeholder="End Time"
            />
            <Select
              className="w-200"
              value={filters.breakStatus}
              onChange={(value) => handleFilterChange("breakStatus", value)}
            >
              <Option value="all">All</Option>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (filters.currentPage - 1) * filters.pageSize + 1;
            const endItem = Math.min(
              filters.currentPage * filters.pageSize,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.currentPage}
                  pageSize={filters.pageSize}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("currentPage", page);
                    handleFilterChange("pageSize", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default TimeSlots;
