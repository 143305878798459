import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearSlice";
import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  DatePicker,
  Pagination,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import moment from "moment";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AcademicYear = () => {
  usePageTitle("Academic Years");
  const dispatch = useDispatch();
  const { data, totalItems, isLoading } = useSelector(
    (state) => state.academicYear
  );
  const [dateRange, setDateRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("all");

  useEffect(() => {
    let start_date = null;
    let end_date = null;

    if (dateRange && dateRange.length === 2) {
      start_date = dateRange[0].format("YYYY-MM-DD");
      end_date = dateRange[1].format("YYYY-MM-DD");
    }

    const offset = (currentPage - 1) * pageSize;
    dispatch(
      fetchAcademicYear({
        start_date,
        end_date,
        is_active: statusFilter,
        limit: pageSize,
        offset,
      })
    );
  }, [dispatch, dateRange, currentPage, pageSize, statusFilter]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 45,
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      width: 100,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      width: 100,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      width: 150,
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `academic/admin/academic-years/${record.id}/`,
              fetchAcademicYear
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/academic-years/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.start_date} - ${record.end_date}`,
                dispatch,
                `academic/admin/academic-years/${record.id}/`,
                fetchAcademicYear
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || []);
  };

  const handleStatusChangeDropdown = (value) => {
    setStatusFilter(value);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    "Start Date": item.start_date || "N/A",
    "End Date": item.end_date || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Start Date", "End Date", "Status"];
    downloadPDF(data, columns, formatTimeSlotsData, "Academic Years Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Academic Years Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Academic Years</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Academic" },
              { title: "Academic Years" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/academic-years/add-new">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10 shadow-sm-2">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <RangePicker
              className="w-100-r"
              onChange={handleDateRangeChange}
              format="YYYY-MM-DD"
              placeholder={["Start Date", "End Date"]}
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={handleStatusChangeDropdown}
            >
              <Option value="all">All</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey="id"
          bordered
          pagination={false}
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (currentPage - 1) * pageSize + 1;
            const endItem = Math.min(currentPage * pageSize, totalItems);

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={handlePaginationChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AcademicYear;
