import React, { useEffect, useState } from "react";

const GalleryPage = () => {
  const [test, setTest] = useState([]);

  useEffect(() => {
    fetch("https://jsonplaceholder.typicode.com/todos")
      .then((res) => res.json())
      .then((json) => setTest(json));
  }, []);
  console.log(test);

  return (
    <div>
      <div>
        {test &&
          test.map((item) => (
            <div key={item.id} className="p-4 rounded-5 shadow-sm border mb-3">
              <h5>{item.title}</h5>
            </div>
          ))}
      </div>
    </div>
  );
};

export default GalleryPage;
