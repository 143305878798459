import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch calendar events with optional filters
export const fetchCalendars = createAsyncThunk(
  "calendar/fetchCalendars",
  async (
    {
      start = "",
      end = "",
      title = "",
      is_active = true,
      limit = 10,
      offset = 0,
    } = {},
    { rejectWithValue }
  ) => {
    try {
      // Initialize params with default limit and offset
      const params = { limit, offset };

      // Conditionally add parameters if they are provided
      if (start) params.start = start;
      if (end) params.end = end;
      if (title) params.title = title;
      if (is_active !== undefined) params.is_active = is_active;

      const response = await getData("academic/admin/calendar/", params);
      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      if (error.response.status === 500) {
        message.error(error.response.statusText);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new calendar event
export const postCalendar = createAsyncThunk(
  "calendar/postCalendar",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      console.log("payload", payload);

      const response = await postData("academic/admin/calendar/", payload);
      if (response.status === 200 || response.status === 201) {
        message.success("Calendar event created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.title?.[0] ||
          error.response.statusText ||
          "Failed to post calendar event.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single calendar event by ID
export const getSingleCalendar = createAsyncThunk(
  "calendar/getSingleCalendar",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`academic/admin/calendar/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update an existing calendar event by ID
export const putCalendar = createAsyncThunk(
  "calendar/putCalendar",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`academic/admin/calendar/${id}/`, payload);
      if (response.status === 200 || response.status === 201) {
        message.success("Calendar event updated successfully!");
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.title?.[0] ||
          error.response.statusText ||
          "Failed to update calendar event.";
        message.error(errorMessage);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Calendar slice
const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    totalItems: 0,
    limit: 10,
    offset: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendars.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCalendars.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchCalendars.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(postCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCalendar.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getSingleCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(getSingleCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putCalendar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putCalendar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putCalendar.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default calendarSlice.reducer;
