import {
  Breadcrumb,
  Spin,
  Button,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SEOInformation from "../../../components/SEOinfo";
import UserInfo from "../../../components/UserInfo";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartments } from "../../../libs/redux/features/teacher-info/departmentsSlice";
import { fetchDesignations } from "../../../libs/redux/features/teacher-info/designationsSlice";
import { getDocIcon } from "../../../components/utility";
import DocumentPopup from "../../../components/DocumentPopup";
import {
  fetchSingleTeacher,
  postTeacher,
  putTeacher,
} from "../../../libs/redux/features/teacher-info/teachersSlice";
import usePageTitle from "../../../components/PageTitle";

const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";
const SERVER_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

const { Option } = Select;

const TeacherForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data: departmentData } = useSelector((state) => state.departments);
  const { data: designationData } = useSelector((state) => state.designations);
  const { singleData, isLoading, error } = useSelector(
    (state) => state.teachers
  );

  useEffect(() => {
    if (departmentData.length === 0) {
      dispatch(fetchDepartments());
    }
    if (designationData.length === 0) {
      dispatch(fetchDesignations());
    }
  }, [departmentData.length, designationData.length, dispatch]);

  const [teacher, setTeacher] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    user: {
      first_name: "",
      last_name: "",
      image: {},
      dob: "",
      bio: "",
      email: "",
      mobile: "",
      password: "",
      confirm_password: "",
      gender: 0,
    },
    employee_id: "",
    hire_date: "",
    qualification: "",
    subjects_taught: "",
    highest_degree: "",
    years_of_experience: null,
    additional_notes: "",
    is_active: true,
    department: null,
    designations: null,
    documents: [],
  });

  usePageTitle(
    id ? `${teacher.user.first_name} ${teacher.user.last_name}` : "Add Teacher"
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleTeacher(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setTeacher({
        seo: singleData.seo,
        user: singleData.user,
        employee_id: "",
        hire_date: singleData.hire_date,
        qualification: singleData.qualification,
        subjects_taught: singleData.subjects_taught,
        highest_degree: singleData.highest_degree,
        years_of_experience: singleData.years_of_experience,
        additional_notes: singleData.additional_notes,
        is_active: singleData.is_active,
        department: singleData.department,
        designations: singleData.designations,
        documents: singleData.documents,
      });
    }
  }, [id, singleData]);

  const handleSEOChange = (update) => {
    setTeacher((prev) => ({
      ...prev,
      seo: update(prev.seo),
    }));
  };

  const handleUserChange = (update) => {
    setTeacher((prev) => ({
      ...prev,
      user: update(prev.user),
    }));
  };

  const handleGenderChange = (gender) => {
    setTeacher((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        gender: gender,
      },
    }));
  };

  const [userImageModal, setUserImageModal] = useState(false);

  const handleUserImageUploadSuccess = (userImg) => {
    setTeacher((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        image: userImg[0],
      },
    }));
    setUserImageModal(false);
  };

  const handleDateChange = (value) => {
    setTeacher((prev) => ({
      ...prev,
      hire_date: value ? dayjs(value).format(SERVER_FORMAT) : "",
    }));
  };

  const [documentModal, setDocumentModal] = useState(false);

  const handleDocumentClose = (removedItem) => {
    setTeacher((prev) => ({
      ...prev,
      documents: prev.documents.filter((item) => item.id !== removedItem.id),
    }));
  };

  const handleDocuments = (data) => {
    setTeacher((prev) => ({
      ...prev,
      documents: [...prev.documents, ...data],
    }));
    setDocumentModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      ...teacher,
      user: {
        ...teacher.user,
        image: teacher.user.image.id,
      },
      documents: teacher.documents.map((item) => item.id),
    };

    console.log("teacher payload", payload);
    if (id) {
      dispatch(putTeacher({ id, payload }));
    } else {
      dispatch(
        postTeacher({
          payload,
          onSuccess: () => {
            setTeacher({
              seo: {
                meta_title: "",
                meta_description: "",
                meta_keywords: "",
              },
              user: {
                first_name: "",
                last_name: "",
                image: {},
                dob: "",
                bio: "",
                email: "",
                mobile: "",
                password: "",
                confirm_password: "",
                gender: 0,
              },
              employee_id: "",
              hire_date: "",
              qualification: "",
              subjects_taught: "",
              highest_degree: "",
              years_of_experience: null,
              additional_notes: "",
              is_active: true,
              department: null,
              designations: null,
              documents: [],
            });
          },
        })
      );
    }
  };

  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id
              ? `${teacher.user.first_name} ${teacher.user.last_name}`
              : "Add Teacher"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Dashboard</Link>,
              },
              {
                title: <Link to="/teachers-info/teachers">Teachers</Link>,
              },
              {
                title: id
                  ? `${teacher.user.first_name} ${teacher.user.last_name}`
                  : "Add Teacher",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <SEOInformation onChange={handleSEOChange} seo={teacher.seo} />
            <UserInfo
              user={teacher.user}
              onUserChange={handleUserChange}
              onGenderChange={handleGenderChange}
              userImageModal={userImageModal}
              setUserImageModal={setUserImageModal}
              onUserImageUploadSuccess={handleUserImageUploadSuccess}
              error={error && error.user}
            />

            <div className="bg-white rounded-10 mb-3">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">General Informations</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Employee ID</p>
                        {error && error.employee_id ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.employee_id})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Employee ID"
                        value={teacher.employee_id}
                        onChange={(e) =>
                          setTeacher((prev) => ({
                            ...prev,
                            employee_id: e.target.value,
                          }))
                        }
                        required
                        status={error && error.employee_id ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Qualification</p>
                        {error && error.qualification ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.qualification})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Qualification"
                        value={teacher.qualification}
                        onChange={(e) =>
                          setTeacher((prev) => ({
                            ...prev,
                            qualification: e.target.value,
                          }))
                        }
                        required
                        status={error && error.qualification ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Hiring Date</p>
                        {error && error.hire_date ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.hire_date})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        showTime
                        size="large"
                        className="w-100 rounded-10"
                        onChange={(date) => handleDateChange(date)}
                        format={DISPLAY_FORMAT}
                        value={
                          teacher.hire_date
                            ? dayjs(teacher.hire_date, SERVER_FORMAT)
                            : null
                        }
                        placeholder="Select Hiring Date"
                        required
                        status={error && error.hire_date ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Subject Taught</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Subject Taught"
                        value={teacher.subjects_taught}
                        onChange={(e) =>
                          setTeacher((prev) => ({
                            ...prev,
                            subjects_taught: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Highest Degree</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Highest Degree"
                        value={teacher.highest_degree}
                        onChange={(e) =>
                          setTeacher((prev) => ({
                            ...prev,
                            highest_degree: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Year of Experience</p>
                        {error && error.years_of_experience ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.years_of_experience})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        type="number"
                        size="large"
                        placeholder="Years of Experience"
                        value={teacher.years_of_experience}
                        onChange={(e) =>
                          setTeacher((prev) => ({
                            ...prev,
                            years_of_experience: e.target.value,
                          }))
                        }
                        required
                        status={
                          error && error.years_of_experience ? "error" : ""
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Additional Notes</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Additional Notes"
                        value={teacher.additional_notes}
                        onChange={(e) =>
                          setTeacher((prev) => ({
                            ...prev,
                            additional_notes: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Designation</p>
                      <Select
                        size="large"
                        className="w-100"
                        value={
                          teacher.designations ||
                          (designationData && designationData[0]?.id)
                        }
                        onChange={(value) =>
                          setTeacher((prev) => ({
                            ...prev,
                            designations: value,
                          }))
                        }
                      >
                        {designationData &&
                          designationData.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Department</p>
                      <Select
                        size="large"
                        className="w-100"
                        value={
                          teacher.department ||
                          (departmentData && departmentData[0]?.id)
                        }
                        onChange={(value) =>
                          setTeacher((prev) => ({
                            ...prev,
                            department: value,
                          }))
                        }
                      >
                        {departmentData &&
                          departmentData.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24} className="">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Documents</p>
                      {error && error.documents ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.documents})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex aic">
                      <Button
                        onClick={() => setDocumentModal(true)}
                        className={`flex justify-center aic rounded-10`}
                        style={{ height: 100, width: 100 }}
                        danger={error && error.documents ? true : false}
                      >
                        + Upload
                      </Button>
                      <div className="ms-3">
                        {teacher.documents && teacher.documents.length > 0
                          ? teacher.documents.map((item) => (
                              <Tag
                                key={item.id}
                                closable
                                onClose={() => handleDocumentClose(item)}
                                icon={getDocIcon(item.doc_type)}
                                className="m-1 p-2 flex aic"
                                color="success"
                              >
                                {item.title}
                              </Tag>
                            ))
                          : "No file selected"}
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={teacher.is_active}
                        onChange={(e) =>
                          setTeacher((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-100 my-5"
            >
              Submit
            </Button>
          </Spin>
        </form>
      </div>
      <DocumentPopup
        open={documentModal}
        onCancel={() => setDocumentModal(false)}
        onUploadSuccess={handleDocuments}
        docDescription={teacher.user.first_name}
      />
    </div>
  );
};

export default TeacherForm;
