import { Breadcrumb, Button, Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchClasses } from "../../../libs/redux/features/academic/classSlice";
import { fetchCourses } from "../../../libs/redux/features/academic/coursesSlice";
import { fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersSlice";
import { fetchTimeSlots } from "../../../libs/redux/features/academic/timeSlotSlice";
import { fetchClassRoutines } from "../../../libs/redux/features/academic/routinesSlice";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  weekDayData,
} from "../../../components/utility";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearSlice";

const { Option } = Select;

const ClassRoutines = () => {
  usePageTitle("Class Routines");

  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector(
    (state) => state.routines
  );

  const { data: classesData } = useSelector((state) => state.classes);
  const { data: coursesData } = useSelector((state) => state.courses);
  const { data: teachersData } = useSelector((state) => state.teachers);
  const { data: timeslotData } = useSelector((state) => state.timeSlots);
  const { data: academicData } = useSelector((state) => state.academicYear);

  useEffect(() => {
    if (academicData.length === 0) {
      dispatch(fetchAcademicYear());
    }
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (coursesData.length === 0) {
      dispatch(fetchCourses());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
    if (timeslotData.length === 0) {
      dispatch(fetchTimeSlots());
    }
  }, [
    dispatch,
    classesData,
    coursesData,
    teachersData,
    timeslotData,
    academicData,
  ]);

  const [filters, setFilters] = useState({
    currentPage: 1,
    pageSize: 10,
    classFilter: "",
    courseFilter: "",
    teacherFilter: "",
    timeSlotFilter: "",
    weekdayFilter: "",
  });

  const offset = (filters.currentPage - 1) * filters.pageSize;

  useEffect(() => {
    dispatch(
      fetchClassRoutines({
        limit: filters.pageSize,
        offset: offset,
        class_info: filters.classFilter === "all" ? "" : filters.classFilter,
        course: filters.courseFilter === "all" ? "" : filters.courseFilter,
        teacher: filters.teacherFilter === "all" ? "" : filters.teacherFilter,
        weekday: filters.weekdayFilter === "all" ? "" : filters.weekdayFilter,
      })
    );
  }, [dispatch, filters, offset]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 45,
      fixed: "left",
      render: (text, record, index) =>
        (filters.currentPage - 1) * filters.pageSize + index + 1,
    },
    {
      title: "Week Day",
      dataIndex: "weekday",
      render: (weekdayId) => {
        const weekday = weekDayData.find((day) => day.id === weekdayId);
        return weekday ? weekday.title : "Unknown";
      },
    },
    {
      title: "Academic Year",
      dataIndex: "academic_year",
      render: (academicYearId) => {
        const academicYear = academicData?.find(
          (year) => year.id === academicYearId
        );
        return academicYear
          ? `${academicYear.start_date} - ${academicYear.end_date}`
          : "N/A";
      },
    },
    {
      title: "Class",
      dataIndex: "class_info",
      render: (classId) => {
        const className = classesData.find((item) => item.id === classId);
        return className ? className.name : "Unknown";
      },
    },
    {
      title: "Course",
      dataIndex: "course",
      render: (courseId) => {
        const courseName = coursesData.find((item) => item.id === courseId);
        return courseName ? courseName.name : "Unknow";
      },
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      render: (teacherId) => {
        const teacherName = teachersData.find((item) => item.id === teacherId);

        return teacherName
          ? `${teacherName.user.first_name} ${teacherName.user.last_name}`
          : "Unknown";
      },
    },
    {
      title: "Time Slot",
      dataIndex: "time_slot",
      render: (slotId) => {
        const slotTime = timeslotData.find((item) => item.id === slotId);
        return slotTime
          ? `${slotTime.start_time} - ${slotTime.end_time}`
          : "Unknown";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/class-routines/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.weekday}`,
                dispatch,
                `academic/admin/class-routines/${record.id}/`,
                fetchClassRoutines
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => {
    const weekday =
      weekDayData.find((day) => day.id === item.weekday)?.title || "N/A";

    const teacher = teachersData.find((teacher) => teacher.id === item.teacher);
    const teacherName = teacher
      ? `${teacher.user.first_name} ${teacher.user.last_name}`
      : "N/A";

    const academicYear = academicData.find(
      (year) => year.id === item.academic_year
    );
    const academicYearDisplay = academicYear
      ? `${academicYear.start_date} - ${academicYear.end_date}`
      : "N/A";

    const className =
      classesData.find((classItem) => classItem.id === item.class_info)?.name ||
      "N/A";

    const courseName =
      coursesData.find((course) => course.id === item.course)?.name || "N/A";

    const timeSlot = timeslotData.find((slot) => slot.id === item.time_slot);
    const timeSlotDisplay = timeSlot
      ? `${timeSlot.start_time} - ${timeSlot.end_time}`
      : "N/A";

    return {
      Serial: index + 1,
      "Week Day": weekday,
      "Academic Year": academicYearDisplay,
      Class: className,
      Courses: courseName,
      Teacher: teacherName,
      "Time Slots": timeSlotDisplay,
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Week Day",
      "Academic Year",
      "Class",
      "Courses",
      "Teacher",
      "Time Slots",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Courses Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Courses Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Class Routines</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Academic" },
              { title: "Class Routines" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/class-routines/add-class-routine">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("classFilter", value)}
            >
              <Option value="all">All Classes</Option>
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("courseFilter", value)}
            >
              <Option value="all">All Courses</Option>
              {coursesData &&
                coursesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("teacherFilter", value)}
            >
              <Option value="all">All Teacher</Option>
              {teachersData &&
                teachersData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.user.first_name} {item.user.last_name}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("timeSlotFilter", value)}
            >
              <Option value="all">All Time Slots</Option>
              {timeslotData &&
                timeslotData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.start_time} - {item.end_time}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("weekdayFilter", value)}
            >
              <Option value="all">All Days</Option>
              {weekDayData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (filters.currentPage - 1) * filters.pageSize + 1;
            const endItem = Math.min(
              filters.currentPage * filters.pageSize,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.currentPage}
                  pageSize={filters.pageSize}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("currentPage", page);
                    handleFilterChange("pageSize", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ClassRoutines;
