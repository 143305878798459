import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchSingleCourse,
  postCourse,
  putCourse,
} from "../../../libs/redux/features/academic/coursesSlice";
import { fetchClasses } from "../../../libs/redux/features/academic/classSlice";
import TextArea from "antd/es/input/TextArea";
import usePageTitle from "../../../components/PageTitle";

const { Option } = Select;

const CoursesForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleData, isLoading, error } = useSelector(
    (state) => state.courses
  );
  const { data: classesData } = useSelector((state) => state.classes);

  usePageTitle(id && singleData.name ? singleData.name : "Add Course");

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleCourse(id));
    }
  }, [id, dispatch]);

  const [course, setCourse] = useState({
    name: "",
    description: "",
    total_marks: 0,
    is_active: true,
    class_level: (classesData && classesData[0].id) || null,
  });

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setCourse({
        name: singleData.name,
        description: singleData.description,
        total_marks: singleData.total_marks,
        is_active: singleData.is_active,
        class_level: singleData.class_level,
      });
    }
  }, [id, singleData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(course);
    const payload = course;
    if (id) {
      dispatch(putCourse({ id, payload }));
    } else {
      dispatch(
        postCourse({
          payload,
          onSuccess: () => {
            setCourse({
              name: "",
              description: "",
              total_marks: 0,
              is_active: true,
              class_level: (classesData && classesData[0].id) || null,
            });
          },
        })
      );
    }
  };

  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id && singleData.name ? singleData.name : "Add Course"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Academic",
              },
              {
                title: <Link to="/academic/courses">Courses</Link>,
              },
              {
                title: id && singleData.name ? singleData.name : "Add Course",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Course Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Course Name</p>
                        {error && error.name ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.name})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Name"
                        required
                        value={course.name}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        status={error && error.name ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Description</p>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Description"
                        required
                        value={course.description}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Total Marks</p>
                        {error && error.total_marks ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.total_marks})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        type="number"
                        size="large"
                        placeholder="Total Marks"
                        required
                        value={course.total_marks}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            total_marks: e.target.value,
                          }))
                        }
                        status={error && error.total_marks ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Class</p>
                        {error && error.class_level ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.class_level})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.class_level ? "error" : ""}
                        className="w-100"
                        size="large"
                        defaultValue={classesData && classesData[0].id}
                        onChange={(value) =>
                          setCourse((prev) => ({
                            ...prev,
                            class_level: value,
                          }))
                        }
                      >
                        {classesData &&
                          classesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={course.is_active}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default CoursesForm;
