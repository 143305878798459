import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch all testimonials
export const fetchTestimonials = createAsyncThunk(
  "testimonials/fetchTestimonials",
  async ({ search, limit = 10, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (search) params.search = search;

      const response = await getData(`website/testimonial/`, params);

      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new testimonial
export const postTestimonial = createAsyncThunk(
  "testimonials/postTestimonial",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData(`website/testimonial/`, payload);
      if (response.status === 200 || 201) {
        message.success("Testimonial item created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error(error.response.statusText);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single testimonial by ID
export const fetchSingleTestimonial = createAsyncThunk(
  "testimonials/fetchSingleTestimonial",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`website/testimonial/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a testimonial by ID
export const putTestimonial = createAsyncThunk(
  "testimonials/putTestimonial",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`website/testimonial/${id}/`, payload);
      if (response.status === 200 || 201) {
        message.success("Testimonial item updated successfully!");
        fetchSingleTestimonial(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error(error.response.statusText);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the testimonial slice
const testimonialSlice = createSlice({
  name: "testimonials",
  initialState: {
    data: [],
    isLoading: false,
    totalItems: 0,
    offset: 0,
    limit: 10,
    error: null,
    singleTestimonial: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTestimonials.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchTestimonials.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.data = [];
      })
      .addCase(postTestimonial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTestimonial.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postTestimonial.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleTestimonial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleTestimonial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleTestimonial = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleTestimonial.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putTestimonial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putTestimonial.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(putTestimonial.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default testimonialSlice.reducer;
