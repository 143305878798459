import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  Input,
  Select,
  Pagination,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersSlice";
import { fetchDepartments } from "../../../libs/redux/features/teacher-info/departmentsSlice";
import { fetchDesignations } from "../../../libs/redux/features/teacher-info/designationsSlice";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { Search } = Input;
const { Option } = Select;

const Teachers = () => {
  usePageTitle("Teachers");

  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector(
    (state) => state.teachers
  );

  const { data: departmentData } = useSelector((state) => state.departments);
  const { data: designationData } = useSelector((state) => state.designations);

  useEffect(() => {
    if (departmentData.length === 0) {
      dispatch(fetchDepartments());
    }
    if (designationData.length === 0) {
      dispatch(fetchDesignations());
    }
  }, [departmentData.length, designationData.length, dispatch]);

  // Unified state for all filters and pagination
  const [filters, setFilters] = useState({
    searchText: "",
    currentPage: 1,
    pageSize: 10,
    statusFilter: "all",
    departmentFilter: "",
    designationFilter: "",
  });

  const offset = (filters.currentPage - 1) * filters.pageSize;

  useEffect(() => {
    dispatch(
      fetchTeachers({
        limit: filters.pageSize,
        offset,
        search: filters.searchText,
        is_active:
          filters.statusFilter === "all" ? undefined : filters.statusFilter,
        department:
          filters.departmentFilter === "All" ? "" : filters.departmentFilter,
        designations:
          filters.designationFilter === "All" ? "" : filters.designationFilter,
      })
    );
  }, [dispatch, filters, offset]);

  // Handlers to update specific filter values
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 30,
      fixed: "left",
      render: (text, record, index) =>
        (filters.currentPage - 1) * filters.pageSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "user",
      width: 200,
      render: (user) => (
        <div className="flex aic">
          <Image
            src={user.image.image}
            alt={user.image.alt_text}
            style={{
              width: 50,
              height: 50,
              borderRadius: 10,
              marginRight: 10,
            }}
          />
          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
      ),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (dprt) => {
        const department = departmentData.find((dep) => dep.id === dprt);
        return <span>{department ? department.name : "Unknown"}</span>;
      },
    },
    {
      title: "Designation",
      dataIndex: "designations",
      render: (dprt) => {
        const designation = designationData.find((dep) => dep.id === dprt);
        return <span>{designation ? designation.name : "Unknown"}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `teacher/admin/teachers/${record.id}/`,
              fetchTeachers
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/teachers-info/teachers/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.employee_id}`,
                dispatch,
                `teacher/admin/teachers/${record.id}/`,
                fetchTeachers
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    "Employee Id": item.employee_id,
    Name: `${item.user.first_name} ${item.user.last_name}` || "N/A",
    // Image: item.user.image.image, // here i have an image url
    Email: item.user.email || "N/A",
    Mobile: item.user.mobile || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
    Department: item.department,
    Designation: item.designations,
    "Hire Date": item.hire_date,
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Employee Id",
      "Name",
      // "Image",
      "Email",
      "Mobile",
      "Department",
      "Designation",
      "Status",
      "Hire Date",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Teacher Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Teacher Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Teachers</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Teachers Info" },
              { title: "Teachers" },
            ]}
          />
        </div>
        <div>
          <Link to="/teachers-info/teachers/add-teacher">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by designation"
              onSearch={(value) => handleFilterChange("searchText", value)}
              className="w-200"
              allowClear
            />
            <Select
              defaultValue="all"
              className="w-200"
              onChange={(value) => handleFilterChange("statusFilter", value)}
            >
              <Option value="all">All</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
            <Select
              className="w-200"
              defaultValue="All"
              onChange={(value) =>
                handleFilterChange("departmentFilter", value)
              }
            >
              <Option value="All">All</Option>
              {departmentData &&
                departmentData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-200"
              defaultValue="All"
              onChange={(value) =>
                handleFilterChange("designationFilter", value)
              }
            >
              <Option value="All">All</Option>
              {designationData &&
                designationData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (filters.currentPage - 1) * filters.pageSize + 1;
            const endItem = Math.min(
              filters.currentPage * filters.pageSize,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.currentPage}
                  pageSize={filters.pageSize}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("currentPage", page);
                    handleFilterChange("pageSize", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Teachers;
