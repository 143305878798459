import { Breadcrumb, Button, Col, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchSingleClassRoutine,
  postClassRoutine,
  putClassRoutine,
} from "../../../libs/redux/features/academic/routinesSlice";
import { fetchClasses } from "../../../libs/redux/features/academic/classSlice";
import { fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearSlice";
import { fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersSlice";
import { fetchTimeSlots } from "../../../libs/redux/features/academic/timeSlotSlice";
import { weekDayData } from "../../../components/utility";
import { fetchCourses } from "../../../libs/redux/features/academic/coursesSlice";
import usePageTitle from "../../../components/PageTitle";

const { Option } = Select;

const ClassRoutineForm = () => {
  const { id } = useParams();

  usePageTitle(id ? "Routine Detail" : "Add Class Routine");
  const dispatch = useDispatch();
  const { singleData, isLoading, error } = useSelector(
    (state) => state.routines
  );

  const { data: classesData } = useSelector((state) => state.classes);
  const { data: academicData } = useSelector((state) => state.academicYear);
  const { data: teachersData } = useSelector((state) => state.teachers);
  const { data: timeSlotsData } = useSelector((state) => state.timeSlots);
  const { data: coursesData } = useSelector((state) => state.courses);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (academicData.length === 0) {
      dispatch(fetchAcademicYear());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
    if (timeSlotsData.length === 0) {
      dispatch(fetchTimeSlots());
    }
    if (coursesData.length === 0) {
      dispatch(fetchCourses());
    }
  }, [
    dispatch,
    classesData.length,
    academicData.length,
    teachersData.length,
    timeSlotsData.length,
    coursesData.length,
  ]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleClassRoutine(id));
    }
  }, [id, dispatch]);

  const [routines, setRoutines] = useState({
    weekday: 0,
    academic_year: null,
    class_info: null,
    teacher: null,
    time_slot: null,
    course: null,
  });

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setRoutines({
        weekday: singleData.weekday,
        academic_year: singleData.academic_year,
        class_info: singleData.class_info,
        teacher: singleData.teacher,
        time_slot: singleData.time_slot,
        course: singleData.course,
      });
    }
  }, [id, singleData]);

  useEffect(() => {
    if (classesData.length > 0 && !id) {
      setRoutines((prev) => ({
        ...prev,
        class_info: classesData[0].id,
      }));
    }

    if (coursesData.length > 0 && !id) {
      setRoutines((prev) => ({
        ...prev,
        course: coursesData[0].id,
      }));
    }
    if (academicData.length > 0 && !id) {
      setRoutines((prev) => ({
        ...prev,
        academic_year: academicData[0].id,
      }));
    }
    if (teachersData.length > 0 && !id) {
      setRoutines((prev) => ({
        ...prev,
        teacher: teachersData[0].id,
      }));
    }
    if (timeSlotsData.length > 0 && !id) {
      setRoutines((prev) => ({
        ...prev,
        time_slot: timeSlotsData[0].id,
      }));
    }
  }, [classesData, coursesData, teachersData, academicData, timeSlotsData, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = routines;
    if (id) {
      dispatch(putClassRoutine({ id, payload }));
    } else {
      dispatch(
        postClassRoutine({
          payload,
          onSuccess: () => {
            setRoutines({
              weekday: weekDayData[0].id,
              class_info: classesData[0].id,
              course: coursesData[0].id,
              academic_year: academicData[0].id,
              teacher: teachersData[0].id,
              time_slot: timeSlotsData[0].id,
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? "Routine Detail" : "Add Class Routine"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Academic",
              },
              {
                title: (
                  <Link to="/academic/class-routines">Class Routines</Link>
                ),
              },
              {
                title: id ? "Routine Detail" : "Add Class Routine",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Class Routine Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Weeek Day</p>
                        {error && error.weekday ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.weekday})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.weekday ? "error" : ""}
                        className="w-100"
                        size="large"
                        defaultValue={0}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            weekday: value,
                          }))
                        }
                      >
                        {weekDayData.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Academic Year</p>
                        {error && error.academic_year ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.academic_year})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.academic_year ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.academic_year}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            academic_year: value,
                          }))
                        }
                      >
                        {academicData &&
                          academicData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.start_date} - {item.end_date}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Class</p>
                        {error && error.class_info ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.class_info})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.class_info ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.class_info}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            class_info: value,
                          }))
                        }
                      >
                        {classesData &&
                          classesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Course</p>
                        {error && error.course ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.course})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.course ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.course}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            course: value,
                          }))
                        }
                      >
                        {coursesData &&
                          coursesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Teacher</p>
                        {error && error.teacher ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.teacher})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.teacher ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.teacher}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            teacher: value,
                          }))
                        }
                      >
                        {teachersData &&
                          teachersData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.user.first_name} {item.user.last_name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* TimeSlot</p>
                        {error && error.time_slot ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.time_slot})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.time_slot ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.time_slot}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            time_slot: value,
                          }))
                        }
                      >
                        {timeSlotsData &&
                          timeSlotsData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.start_time} - {item.end_time}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default ClassRoutineForm;
