import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  DatePicker,
  Input,
  Pagination,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../../libs/redux/features/website/eventSlice";
import moment from "moment";
import {
  downloadPDF,
  downloadXLS,
  formatDateTime,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;

const Events = () => {
  usePageTitle("Events");

  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector((state) => state.events);
  console.log("events", data);

  const [dateRange, setDateRange] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const offset = (currentPage - 1) * pageSize;

  useEffect(() => {
    let start_date = null;
    let end_date = null;

    if (dateRange && dateRange.length === 2) {
      start_date = dateRange[0].format("YYYY-MM-DD");
      end_date = dateRange[1].format("YYYY-MM-DD");
    }

    dispatch(
      fetchEvents({
        start_date,
        end_date,
        search: searchTerm,
        is_active: isActive,
        limit: pageSize,
        offset,
      })
    );
  }, [dispatch, dateRange, searchTerm, isActive, offset, pageSize]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 30,
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },

    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (date) =>
        date ? moment.utc(date).format("hh:mm A | Do MMMM, YYYY") : "",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      render: (date) =>
        date ? moment.utc(date).format("hh:mm A | Do MMMM, YYYY") : "",
    },
    {
      title: "Venue",
      dataIndex: "venue",
    },
    {
      title: "Status",
      dataIndex: "is_active",

      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `website/event/${record.id}/`,
              fetchEvents
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/website/components/events/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `website/event/${record.id}/`,
                fetchEvents
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || []);
    setCurrentPage(1);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };
  const handleStatusChangeDropdown = (value) => {
    setIsActive(value);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Title: item.title || "N/A",
    "Start Date": formatDateTime(item.start_date) || "N/A",
    "End Date": formatDateTime(item.end_date) || "N/A",
    Venue: item.venue || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Title",
      "Start Date",
      "End Date",
      "Venue",
      "Status",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Events Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Events Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Events</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Components" },
              { title: "Events" },
            ]}
          />
        </div>
        <div>
          <Link to="/website/components/events/add-new">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <RangePicker
              className="w-100-r"
              onChange={handleDateRangeChange}
              format="YYYY-MM-DD"
              placeholder={["Start Date", "End Date"]}
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={handleStatusChangeDropdown}
            >
              <Option value="all">All</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
            <Search
              placeholder="Search Title"
              onSearch={handleSearch}
              className="w-200"
            />
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (currentPage - 1) * pageSize + 1;
            const endItem = Math.min(currentPage * pageSize, totalItems);

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={handlePageChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Events;
