import {
  Breadcrumb,
  Col,
  DatePicker,
  Image,
  Input,
  Row,
  Spin,
  Button,
  Checkbox,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import UploadButton from "../../../components/UploadButton";
import ImagePopup from "../../../components/ImagePopup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleEvent,
  postEvent,
  putEvent,
} from "../../../libs/redux/features/website/eventSlice";
import dayjs from "dayjs";
import usePageTitle from "../../../components/PageTitle";

const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";
const SERVER_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

const EventsForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleData, isLoading, error } = useSelector((state) => state.events);

  const [event, setEvent] = useState({
    seo: {
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    },
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    venue: "",
    is_active: true,
    featured_image: {},
  });

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSingleEvent(id));
    }
  }, [id, dispatch]);

  usePageTitle(!isNaN(id) ? singleData.title : "Add Event");

  useEffect(() => {
    if (!isNaN(id) && Object.keys(singleData).length > 0 && singleData.seo) {
      setEvent({
        ...singleData,
        start_date: singleData.start_date
          ? dayjs(singleData.start_date).format(DISPLAY_FORMAT)
          : "",
        end_date: singleData.end_date
          ? dayjs(singleData.end_date).format(DISPLAY_FORMAT)
          : "",
      });
    }
  }, [id, singleData]);

  const [featuredImageModal, setFeaturedImageModal] = useState(false);

  const handleFeaturedImageUploadSuccess = (newImage) => {
    setEvent((prev) => ({
      ...prev,
      featured_image: newImage[0],
    }));
    setFeaturedImageModal(false);
  };

  const handleDateChange = (name, value) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value ? dayjs(value).format(DISPLAY_FORMAT) : "",
      [`${name}_iso`]: value ? dayjs(value).format(SERVER_FORMAT) : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNaN(id)) {
      let payload = {
        ...event,
        start_date: event.start_date_iso,
        end_date: event.end_date_iso,
        featured_image: event.featured_image.id,
      };

      dispatch(putEvent({ id, payload }));
    } else {
      const image = event.featured_image.id;
      let payload = {
        ...event,
        start_date: event.start_date_iso,
        end_date: event.end_date_iso,
        featured_image: image,
      };

      if (image) {
        dispatch(
          postEvent({
            payload,
            onSuccess: () => {
              setEvent({
                seo: {
                  meta_title: "",
                  meta_description: "",
                  meta_keywords: "",
                },
                title: "",
                description: "",
                start_date: "",
                end_date: "",
                venue: "",
                is_active: true,
                featured_image: {},
              });
            },
          })
        );
      } else {
        message.error("No featured image detected");
      }
    }
  };

  return (
    <div>
      <div className="breadcrumb p-4">
        <div>
          <h1 className="fs-2 text-dark">
            {!isNaN(id) ? singleData.title : "Add Event"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Website",
              },
              {
                title: "components",
              },
              {
                title: <Link to="/website/components/events">Events</Link>,
              },
              {
                title: !isNaN(id) ? `${singleData.title}` : "Add Event",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">SEO Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Meta Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Title"
                        value={event.seo.meta_title || ""}
                        onChange={(e) =>
                          setEvent((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_title: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Meta Descriptions</p>
                      <TextArea
                        className="w-100"
                        placeholder="Meta Descriptions"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={event.seo.meta_description || ""}
                        onChange={(e) =>
                          setEvent((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_description: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="">
                      <p className="mb-2 ps-2 fw-6">
                        * Meta Keywords (comma separated)
                      </p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Meta Keywords"
                        value={event.seo.meta_keywords || ""}
                        onChange={(e) =>
                          setEvent((prev) => ({
                            ...prev,
                            seo: {
                              ...prev.seo,
                              meta_keywords: e.target.value,
                            },
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="bg-white rounded-10 mb-5">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Page Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Title</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Title"
                        value={event.title}
                        onChange={(e) =>
                          setEvent((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Brief Description</p>
                      <Editor
                        apiKey="58fdmypbltmy8x4mc23zkbd4uzw5feyqvv8kox1fsj41wf7f"
                        value={event.description}
                        onEditorChange={(value) =>
                          setEvent((prev) => ({
                            ...prev,
                            description: value,
                          }))
                        }
                        init={{
                          height: 500,
                          plugins: ["lists"],
                          toolbar: `undo redo | formatselect | bold italic forecolor backcolor | 
                          fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | 
                          bullist numlist outdent indent | removeformat | help`,
                          fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt", // Customize font sizes
                          content_style:
                            "body { font-family: 'Cabin', sans-serif; }",
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Start Date</p>
                        {error && error.start_date ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.start_date})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        className="w-100 rounded-10"
                        size="large"
                        showTime
                        name="start_date"
                        onChange={(date) =>
                          handleDateChange("start_date", date)
                        }
                        format={DISPLAY_FORMAT}
                        value={
                          event.start_date
                            ? dayjs(event.start_date, DISPLAY_FORMAT)
                            : null
                        }
                        placeholder="Select Start Date"
                        status={error && error.start_date ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* End Date</p>
                        {error && error.end_date ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.end_date})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        size="large"
                        className="w-100 rounded-10"
                        showTime
                        name="end_date"
                        onChange={(date) => handleDateChange("end_date", date)}
                        format={DISPLAY_FORMAT}
                        value={
                          event.end_date
                            ? dayjs(event.end_date, DISPLAY_FORMAT)
                            : null
                        }
                        placeholder="Select End Date"
                        status={error && error.end_date ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Venue</p>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Venue"
                        value={event.venue}
                        onChange={(e) =>
                          setEvent((prev) => ({
                            ...prev,
                            venue: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-5">
                      <p className="mb-2 ps-2 fw-6">* Featured Image</p>
                      <div className="image-preview-grid">
                        {event.featured_image &&
                        Object.keys(event.featured_image).length > 0 ? (
                          <Image
                            height={100}
                            width={100}
                            className="object-cover rounded-5"
                            alt={event.featured_image.alt_text}
                            src={event.featured_image.image}
                          />
                        ) : (
                          ""
                        )}
                        <UploadButton
                          onClick={() => setFeaturedImageModal(true)}
                          height={100}
                          width={100}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-5">
                      <Checkbox
                        checked={event.is_active}
                        onChange={(e) =>
                          setEvent((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
      {featuredImageModal && (
        <ImagePopup
          open={featuredImageModal}
          onCancel={() => setFeaturedImageModal(false)}
          onUploadSuccess={handleFeaturedImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={`${event.title} - featured image`}
        />
      )}
    </div>
  );
};

export default EventsForm;
