import { Input, Space, Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { deleteData, patchData } from "../libs/axios/axiosInstance";
import { FaFileImage, FaFilePdf, FaFileAlt } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

export const determineDocType = (file) => {
  const extension = file.name.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "png", "webp"].includes(extension)) return 0;
  if (["pdf"].includes(extension)) return 1;
  return 2;
};

export const getDocIcon = (doc_type) => {
  switch (doc_type) {
    case 0:
      return <FaFileImage />;
    case 1:
      return <FaFilePdf />;
    default:
      return <FaFileAlt />;
  }
};

export const createFormData = (file, title, owner, docDescription) => {
  const formData = new FormData();
  formData.append("owner", owner);
  formData.append("document", file);
  formData.append("doc_type", determineDocType(file));
  formData.append("title", title || "Untitled");
  formData.append("description", docDescription || "Untitled");
  return formData;
};

export const createFormDataImage = (
  file,
  title,
  caption,
  alt_text,
  isGallery
) => {
  const formData = new FormData();
  formData.append("alt_text", alt_text || "Sample alt text");
  formData.append("title", title || "Untitled");
  formData.append("caption", caption || "Sample caption");
  formData.append("image", file);
  formData.append("is_gallery_image", isGallery);
  return formData;
};

// helpers for table data

// Utility function for column search props
export const getColumnSearchProps = (
  dataIndex,
  searchedColumn,
  setSearchedColumn
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() =>
          handleSearch(selectedKeys, confirm, dataIndex, setSearchedColumn)
        }
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, setSearchedColumn)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm, setSearchedColumn)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  render: (text) =>
    searchedColumn === dataIndex ? <strong>{text}</strong> : text,
});

// Helper function for search handling
const handleSearch = (selectedKeys, confirm, dataIndex, setSearchedColumn) => {
  confirm();
  setSearchedColumn(dataIndex);
};

// Helper function for resetting filters
const handleReset = (clearFilters, confirm, setSearchedColumn) => {
  clearFilters();
  confirm();
  setSearchedColumn("");
};

//

export const handleDelete = async (
  name,
  dispatch,
  url,
  fetchCallback,
  params = null
) => {
  Modal.confirm({
    title: `Are you sure you want to delete ${name}?`,
    content: "This action cannot be undone.",
    okText: "Yes, Delete",
    okType: "danger",
    cancelText: "No, Cancel",
    onOk: async () => {
      try {
        await deleteData(url);
        message.success(`${name} has been deleted.`);
        if (fetchCallback) {
          if (params) {
            dispatch(fetchCallback({ ...params, offset: 0 }));
          } else {
            dispatch(fetchCallback({ limit: 32, offset: 0 }));
          }
        }
      } catch (error) {
        message.error("Failed to delete the item. Please try again.");
      }
    },
  });
};

//

export const handleStatusChange = async (
  checked,
  record,
  dispatch,
  statusUrl,
  functionCall
) => {
  const updatedStatus = checked;
  console.log(statusUrl);

  try {
    await patchData(statusUrl, {
      is_active: updatedStatus,
    });
    message.success(
      ` ${
        record.name || record.title || record.job_title || record.id
      } has been ${updatedStatus ? "activated" : "deactivated"}.`
    );
    dispatch(functionCall());
  } catch (error) {
    message.error("Failed to update the status. Please try again.");
  }
};

export const handleSwitchChange = async (
  key,
  checked,
  record,
  dispatch,
  statusUrl,
  functionCall
) => {
  const updatedStatus = checked;
  try {
    await patchData(statusUrl, {
      [key]: updatedStatus,
    });
    message.success(
      ` ${
        record.name || record.title || record.job_title || record.id
      } has been ${updatedStatus ? "activated" : "deactivated"}.`
    );
    dispatch(functionCall());
  } catch (error) {
    message.error("Failed to update the status. Please try again.");
  }
};

//

export const renderDescription = (text) => {
  const truncatedText = text.split(" ").slice(0, 3).join(" ");
  return (
    <Tooltip title={text}>
      <span>{truncatedText}...</span>
    </Tooltip>
  );
};

export const weekDayData = [
  { id: 0, title: "Saturday" },
  { id: 1, title: "Sunday" },
  { id: 2, title: "Monday" },
  { id: 3, title: "Tuesday" },
  { id: 4, title: "Wednesday" },
  { id: 5, title: "Thursday" },
  { id: 6, title: "Friday" },
];

export const enterFullscreen = () => {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    // Firefox
    document.documentElement.mozRequestFullScreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    document.documentElement.webkitRequestFullscreen();
  } else if (document.documentElement.msRequestFullscreen) {
    // IE/Edge
    document.documentElement.msRequestFullscreen();
  }
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    // Chrome, Safari and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    // IE/Edge
    document.msExitFullscreen();
  }
};

//Utility function for downloading data as an pdf file

export const downloadPDF = (data, columns, formatData, title = "Report") => {
  const doc = new jsPDF();

  doc.text(title, 20, 10);

  const formattedData = data.map((item, index) => formatData(item, index));

  const headers = columns;

  const tableData = formattedData.map((item) =>
    headers.map((header) => item[header])
  );

  doc.autoTable({
    head: [headers],
    body: tableData,
    startY: 20,
  });

  doc.save(`${title}.pdf`);
};

// Utility function for downloading data as an XLS file
export const downloadXLS = (data, formatData, title = "Report") => {
  const formattedData = data.map((item, index) => formatData(item, index));
  const headers = Object.keys(formattedData[0] || {});
  const worksheet = XLSX.utils.json_to_sheet(formattedData, {
    header: headers,
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, title);
  XLSX.writeFile(workbook, `${title}.xlsx`);
};

export const formatDateTime = (dateString) => {
  return dayjs(dateString).format("YY-MM-DD | HH:mm:ss");
};
