import { Modal, Spin, Upload } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { createFormData } from "./utility";
import { uploadDocument } from "../libs/axios/axiosInstance";

const { Dragger } = Upload;

const DocumentPopup = ({ open, onCancel, onUploadSuccess, docDescription }) => {
  const userData = JSON.parse(localStorage.getItem("user_data") || "{}");
  const userId = userData.id;

  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ fileList }) => {
    console.log("onchange files", fileList);

    setDocs(fileList.map((file) => file.originFileObj));
  };

  const handleDocumentSubmit = async () => {
    setLoading(true);
    let newDocuments = [];
    if (docs && docs.length > 0) {
      try {
        const uploadPromises = docs.map((doc) => {
          const formData = createFormData(
            doc,
            doc.name,
            userId,
            docDescription
          );
          return uploadDocument(formData);
        });
        console.log("upload promises", uploadPromises);

        const uploadResponses = await Promise.all(uploadPromises);

        newDocuments = uploadResponses.map((response) => response.data);

        onUploadSuccess(newDocuments);
        setLoading(false);
      } catch (error) {
        console.log("Error uploading documents from popup", error);
        setLoading(false);
        return;
      }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={handleDocumentSubmit}
      width={800}
      className="custom-modal"
    >
      <Spin spinning={loading}>
        <div className="mt-5">
          <Dragger
            multiple
            onChange={handleChange}
            style={{ height: 700 }}
            listType="picture"
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Any file type is supported.
            </p>
          </Dragger>
        </div>
      </Spin>
    </Modal>
  );
};

export default DocumentPopup;
