import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

// Fetch all specialities
export const fetchSpecialities = createAsyncThunk(
  "specialities/fetchSpecialities",
  async ({ is_active, limit = 10, offset = 0 } = {}, { rejectWithValue }) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (is_active !== undefined) params.is_active = is_active;

      const response = await getData(`website/schoolspeciality/`, params);

      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Post a new speciality
export const postSpeciality = createAsyncThunk(
  "specialities/postSpeciality",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData(`website/schoolspeciality/`, payload);
      if (response.status === 200 || 201) {
        message.success("Speciality item created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error("Failed to create speciality item.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a single speciality by ID
export const fetchSingleSpeciality = createAsyncThunk(
  "specialities/fetchSingleSpeciality",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`website/schoolspeciality/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a speciality by ID
export const putSpeciality = createAsyncThunk(
  "specialities/putSpeciality",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(
        `website/schoolspeciality/${id}/`,
        payload
      );
      if (response.status === 200 || 201) {
        message.success("Speciality item updated successfully!");
        fetchSingleSpeciality(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error(error.response.statusText);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the speciality slice
const specialitySlice = createSlice({
  name: "specialities",
  initialState: {
    data: [],
    isLoading: false,
    totalItems: 0,
    offset: 0,
    limit: 32,
    error: null,
    singleSpeciality: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecialities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSpecialities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchSpecialities.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.data = [];
      })
      .addCase(postSpeciality.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postSpeciality.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postSpeciality.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchSingleSpeciality.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleSpeciality.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleSpeciality = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleSpeciality.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putSpeciality.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putSpeciality.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(putSpeciality.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default specialitySlice.reducer;
