import { Breadcrumb, Input, Pagination, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchClasses } from "../../../libs/redux/features/academic/classSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { fetchSections } from "../../../libs/redux/features/academic/sectionSlice";

const { Search } = Input;
const { Option } = Select;

const Sections = () => {
  usePageTitle("Sections");
  const dispatch = useDispatch();

  const { data, isLoading, totalItems } = useSelector(
    (state) => state.sections
  );

  const { data: classesData } = useSelector((state) => state.classes);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (data.length === 0) {
      dispatch(fetchSections());
    }
  }, [classesData.length, data.length, dispatch]);

  const [filters, setFilters] = useState({
    searchText: "",
    currentPage: 1,
    pageSize: 10,
    statusFilter: "all",
    classFilter: "",
  });

  const offset = (filters.currentPage - 1) * filters.pageSize;

  useEffect(() => {
    dispatch(
      fetchSections({
        limit: filters.pageSize,
        offset,
        name: filters.searchText,
        is_active:
          filters.statusFilter === "all" ? undefined : filters.statusFilter,
        class_level: filters.classFilter === "all" ? "" : filters.classFilter,
      })
    );
  }, [dispatch, filters, offset]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) =>
        (filters.currentPage - 1) * filters.pageSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
    },
    {
      title: "Class Level",
      dataIndex: "class_level",
      render: (classId) => {
        const classData = classesData.find((item) => item.id === classId);
        return classData ? classData.name : "Unknown";
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active) =>
        is_active ? (
          <Tag bordered={false} color="success">
            Active
          </Tag>
        ) : (
          <Tag bordered={false} color="warning">
            Inactive
          </Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `academic/admin/sections/${record.id}/`,
                fetchSections
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => {
    const classId = classesData.find((items) => items.id === item.class_level);

    const className = classId ? classId.name : "N/A";

    return {
      Serial: index + 1,
      Name: item.name || "N/A",
      Capacity: item.capacity || "N/A",
      Description: item.description || "N/A",
      Class: className,
      Status: item.is_active ? "Active" : "Inactive",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Name",
      "Capacity",
      "Class",
      "Description",
      "Status",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Sections Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Sections Data");
  };

  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">Sections</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Academic" },
              { title: "Sections" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by name"
              onSearch={(value) => handleFilterChange("searchText", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("statusFilter", value)}
            >
              <Option value="all">All</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("classFilter", value)}
            >
              <Option value="all">All</Option>
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (filters.currentPage - 1) * filters.pageSize + 1;
            const endItem = Math.min(
              filters.currentPage * filters.pageSize,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.currentPage}
                  pageSize={filters.pageSize}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("currentPage", page);
                    handleFilterChange("pageSize", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Sections;
