import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Students from "../view/student-data/students/Students";

export default function StudentsRoutes() {
  return (
    <Routes>
      <Route
        path="/students"
        element={<ProtectedRoutes Component={Students} />}
      />
    </Routes>
  );
}
