import { Breadcrumb, Button, Checkbox, Col, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import usePageTitle from "../../../components/PageTitle";
import {
  fetchSingleDesignation,
  postDesignation,
  putDesignation,
} from "../../../libs/redux/features/teacher-info/designationsSlice";

const DesignationForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleData, isLoading, error } = useSelector(
    (state) => state.designations
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleDesignation(id));
    }
  }, [id, dispatch]);

  const [designation, setDesignation] = useState({
    name: "",
    sequence: "",
    is_active: true,
  });

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setDesignation({
        name: singleData.name,
        sequence: singleData.sequence,
        is_active: singleData.is_active,
      });
    }
  }, [id, singleData]);

  usePageTitle(id ? singleData.name : "Add Designation");

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = designation;

    if (!id) {
      dispatch(
        postDesignation({
          payload,
          onSuccess: () => {
            setDesignation({
              name: "",
              sequence: "",
              is_active: true,
            });
          },
        })
      );
    } else {
      dispatch(putDesignation({ id, payload }));
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? singleData.name : "Add Designation"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Teachers Info",
              },
              {
                title: (
                  <Link to="/teachers-info/designations">Designations</Link>
                ),
              },
              {
                title: id ? singleData.name : "Add Designation",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Designation Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Designation Name</p>
                        {error && error.name ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.name})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Name"
                        required
                        value={designation.name}
                        onChange={(e) =>
                          setDesignation((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        status={error && error.name ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Sequence</p>
                        {error && error.sequence ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.sequence})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Name"
                        required
                        value={designation.sequence}
                        onChange={(e) =>
                          setDesignation((prev) => ({
                            ...prev,
                            sequence: e.target.value,
                          }))
                        }
                        status={error && error.sequence ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={designation.is_active}
                        onChange={(e) =>
                          setDesignation((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Add Class
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default DesignationForm;
