import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import usePageTitle from "../../../components/PageTitle";
import { fetchAcademicYear } from "../../../libs/redux/features/academic/academicYearSlice";
import {
  getSingleCalendar,
  postCalendar,
  putCalendar,
} from "../../../libs/redux/features/academic/calendarSlice";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import dayjs from "dayjs";

const { Option } = Select;

const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";
const SERVER_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

const CalendarForm = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { singleData, isLoading, error } = useSelector(
    (state) => state.calendar
  );
  const { data: academicData } = useSelector((state) => state.academicYear);

  useEffect(() => {
    if (academicData.length === 0) {
      dispatch(fetchAcademicYear());
    }
  }, [dispatch, academicData.length]);

  usePageTitle(id ? singleData.title : "Add Calendar");

  useEffect(() => {
    if (id) {
      dispatch(getSingleCalendar(id));
    }
  }, [id, dispatch]);

  const [calendar, setCalendar] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    all_day: false,
    is_active: true,
    academic_year: null,
  });

  useEffect(() => {
    if (!id && academicData.length > 0) {
      setCalendar((prev) => ({
        ...prev,
        academic_year: academicData[0].id,
      }));
    }
  }, [id, academicData]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setCalendar({
        title: singleData.title,
        description: singleData.description,
        start: singleData.start,
        end: singleData.end,
        all_day: singleData.all_day,
        is_active: singleData.is_active,
        academic_year: singleData.academic_year,
      });
    }
  }, [id, singleData]);

  const handleDateChange = (name, value) => {
    setCalendar((prevEvent) => ({
      ...prevEvent,
      [name]: value ? dayjs(value).format(DISPLAY_FORMAT) : "",
      [`${name}_iso`]: value ? dayjs(value).format(SERVER_FORMAT) : "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(calendar);
    const payload = {
      ...calendar,
      start: calendar.start_iso,
      end: calendar.end_iso,
    };
    if (id) {
      dispatch(putCalendar({ id, payload }));
    } else {
      dispatch(
        postCalendar({
          payload,
          onSuccess: () => {
            setCalendar({
              title: "",
              description: "",
              start: "",
              end: "",
              all_day: false,
              is_active: true,
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? calendar.title : "Add Calendar"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Academic",
              },
              {
                title: <Link to="/academic/calendars">Calendars</Link>,
              },
              {
                title: id ? calendar.title : "Add Calendar",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Calendar Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Title</p>
                        {error && error.title ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.title})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Name"
                        required
                        value={calendar.title}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        status={error && error.title ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Description</p>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Description"
                        required
                        value={calendar.description}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Start Date</p>
                        {error && error.start ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.start})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        size="large"
                        className="w-100 rounded-10"
                        showTime
                        name="start"
                        onChange={(date) => handleDateChange("start", date)}
                        format={DISPLAY_FORMAT}
                        value={
                          calendar.start
                            ? dayjs(calendar.start, DISPLAY_FORMAT)
                            : null
                        }
                        placeholder="Start Date"
                        status={error && error.start ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* End Date</p>
                        {error && error.end ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.end})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        size="large"
                        className="w-100 rounded-10"
                        showTime
                        name="end"
                        onChange={(date) => handleDateChange("end", date)}
                        format={DISPLAY_FORMAT}
                        value={
                          calendar.end
                            ? dayjs(calendar.end, DISPLAY_FORMAT)
                            : null
                        }
                        placeholder="End Date"
                        status={error && error.end ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Academic Year</p>
                        {error && error.academic_year ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.academic_year})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.academic_year ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={calendar.academic_year}
                        onChange={(value) =>
                          setCalendar((prev) => ({
                            ...prev,
                            academic_year: value,
                          }))
                        }
                      >
                        {academicData &&
                          academicData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.start_date} - {item.end_date}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-3">
                      <Checkbox
                        checked={calendar.all_day}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            all_day: e.target.checked,
                          }))
                        }
                      >
                        All Day ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-5">
                      <Checkbox
                        checked={calendar.is_active}
                        onChange={(e) =>
                          setCalendar((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default CalendarForm;
