import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, postData, putData } from "../../../axios/axiosInstance";
import { message } from "antd";

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (
    { start_date, end_date, is_active, limit = 10, offset = 0, search } = {},
    { rejectWithValue }
  ) => {
    try {
      const params = {
        limit,
        offset,
      };

      if (start_date) params.start_date = start_date;
      if (end_date) params.end_date = end_date;
      if (is_active !== undefined) params.is_active = is_active;
      if (search) params.search = search;

      const response = await getData(`website/event/`, params);

      return {
        data: response.data.results,
        total: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSingleEvent = createAsyncThunk(
  "events/fetchSingleEvent",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getData(`website/event/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postEvent = createAsyncThunk(
  "career/postEvent",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await postData("website/event/", payload);
      if (response.status === 200 || 201) {
        message.success("event item created successfully!");
        if (onSuccess) {
          onSuccess();
        }
      }
      return response.data;
    } catch (error) {
      console.log("post event", error);

      if (error.response.status === 400) {
        const errorMessage =
          error.response.data.start_date?.[0] ||
          error.response.data.end_date?.[0] ||
          "Failed to post event item.";
        message.error(errorMessage || "Failed to create event item.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const putEvent = createAsyncThunk(
  "career/putEvent",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await putData(`website/event/${id}/`, payload);

      if (response.status === 200 || 201) {
        message.success("event item updated successfully!");
        fetchSingleEvent(id);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        message.error(
          error.response.data.start_date?.[0] ||
            error.response.data.end_date?.[0] ||
            "Failed to update event item."
        );
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const eventSlice = createSlice({
  name: "events",
  initialState: {
    data: [],
    singleData: {},
    isLoading: false,
    offset: 0,
    limit: 10,
    totalItems: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalItems = action.payload.total;
        state.error = null;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchSingleEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleData = action.payload;
        state.error = null;
      })
      .addCase(fetchSingleEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.singleData = {};
        state.error = action.payload;
      })
      .addCase(postEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postEvent.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(putEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.singleData = action.payload;
        state.error = null;
      })
      .addCase(putEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default eventSlice.reducer;
