import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  Pagination,
  Select,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { fetchCalendars } from "../../../libs/redux/features/academic/calendarSlice";
import dayjs from "dayjs";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { Search } = Input;
const { Option } = Select;

const Calendar = () => {
  usePageTitle("Academic Calendars");

  const dispatch = useDispatch();
  const { data, isLoading, totalItems } = useSelector(
    (state) => state.calendar
  );

  const [filters, setFilters] = useState({
    searchText: "",
    currentPage: 1,
    pageSize: 10,
    statusFilter: "all",
    start: "",
    end: "",
  });

  const offset = (filters.currentPage - 1) * filters.pageSize;

  useEffect(() => {
    dispatch(
      fetchCalendars({
        limit: filters.pageSize,
        offset: offset,
        title: filters.searchText,
        is_active:
          filters.statusFilter === "all" ? undefined : filters.statusFilter,
        start: filters.start,
        end: filters.end,
      })
    );
  }, [dispatch, filters, offset]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 30,
      fixed: "left",
      render: (text, record, index) =>
        (filters.currentPage - 1) * filters.pageSize + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Start",
      dataIndex: "start",
    },
    {
      title: "End",
      dataIndex: "end",
    },
    {
      title: "All Day ?",
      dataIndex: "all_day",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `academic/admin/calendar/${record.id}/`,
              fetchCalendars
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/calendars/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `academic/admin/calendar/${record.id}/`,
                fetchCalendars
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Title: item.title || "N/A",
    "Start Time": item.start || "N/A",
    "End Time": item.end || "N/A",
    Description: item.description || "N/A",
    "All Day ?": item.all_day ? "Yes" : "No",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "TItle",
      "Start Time",
      "End Time",
      "Description",
      "All Day ?",
      "Status",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Academic Calendar Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Academic Calendar Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Academic Calendars</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Academic" },
              { title: "Calendars" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/calendars/add-calendar">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by title"
              onSearch={(value) => handleFilterChange("title", value)}
              className="w-200"
              allowClear
            />
            <DatePicker
              className="w-200"
              placeholder="Start date"
              value={filters.start ? dayjs(filters.start) : null}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  start: date ? date.format("YYYY-MM-DD") : null,
                }))
              }
            />
            <DatePicker
              className="w-200"
              placeholder="End date"
              value={filters.end ? dayjs(filters.end) : null}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  end: date ? date.format("YYYY-MM-DD") : null,
                }))
              }
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("statusFilter", value)}
            >
              <Option value="all">All</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (filters.currentPage - 1) * filters.pageSize + 1;
            const endItem = Math.min(
              filters.currentPage * filters.pageSize,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={filters.currentPage}
                  pageSize={filters.pageSize}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    handleFilterChange("currentPage", page);
                    handleFilterChange("pageSize", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Calendar;
