import { RiHome3Fill } from "react-icons/ri";
import { PiStudentFill } from "react-icons/pi";
import { SiMicrosoftacademic } from "react-icons/si";
import { Link } from "react-router-dom";
import { PiChalkboardTeacherBold } from "react-icons/pi";
import { CgWebsite } from "react-icons/cg";

export const sidebrMenus = [
  {
    key: "1",
    icon: <RiHome3Fill />,
    label: <Link to="/">Home</Link>,
  },
  {
    key: "2",
    icon: <SiMicrosoftacademic />,
    label: "Academic",
    children: [
      {
        key: "21",
        label: <Link to="/academic/academic-years">Academic Years</Link>,
      },
      {
        key: "22",
        label: <Link to="/academic/classes">Classes</Link>,
      },
      // {
      //   key: "23",
      //   label: <Link to="/academic/sections">Sections</Link>,
      // },
      {
        key: "24",
        label: <Link to="/academic/time-slots">Time Slots</Link>,
      },
      {
        key: "26",
        label: <Link to="/academic/courses">Courses</Link>,
      },
      {
        key: "25",
        label: <Link to="/academic/class-routines">Class Routines</Link>,
      },
      {
        key: "27",
        label: <Link to="/academic/calendars">Calendars</Link>,
      },
    ],
  },
  {
    key: "5",
    icon: <PiChalkboardTeacherBold />,
    label: "Teachers Info",
    children: [
      {
        key: "511",
        label: <Link to="/teachers-info/departments">Departments</Link>,
      },
      {
        key: "522",
        label: <Link to="/teachers-info/designations">Designations</Link>,
      },
      {
        key: "523",
        label: <Link to="/teachers-info/teachers">Teachers</Link>,
      },
    ],
  },
  {
    key: "3",
    icon: <PiStudentFill />,
    label: "Student Data",
    children: [
      {
        key: "31",
        label: <Link to="/student-data/students">Students</Link>,
      },
    ],
  },
  {
    key: "4",
    icon: <CgWebsite />,
    label: "Website",
    children: [
      {
        key: "56",
        label: "Components",
        children: [
          {
            key: "43",
            label: <Link to="/website/components/careers">Careers</Link>,
          },
          {
            key: "47",
            label: <Link to="/website/components/events">Events</Link>,
          },
          {
            key: "52",
            label: (
              <Link to="/website/components/school-schedules">
                School Schedules
              </Link>
            ),
          },
          {
            key: "53",
            label: (
              <Link to="/website/components/image-gallery">Image Gallery</Link>
            ),
          },

          {
            key: "55",
            label: (
              <Link to="/website/components/school-facilities">
                School Facilities
              </Link>
            ),
          },
          {
            key: "561",
            label: (
              <Link to="/website/components/school-specialities">
                School Specialities
              </Link>
            ),
          },
          {
            key: "562",
            label: <Link to="/website/components/sliders">Sliders</Link>,
          },
          {
            key: "563",
            label: (
              <Link to="/website/components/testimonials">Testimonials</Link>
            ),
          },
          {
            key: "564",
            label: <Link to="/website/components/notices">Notices</Link>,
          },
          {
            key: "46",
            label: (
              <Link to="/website/components/contact-us-messages">
                Contact Us Messages
              </Link>
            ),
          },
        ],
      },
      {
        key: "57",
        label: "Pages",
        children: [
          {
            key: "49",
            label: <Link to="/website/pages/home">Home</Link>,
          },
          {
            key: "41",
            label: <Link to="/website/pages/about">About</Link>,
          },
          {
            key: "42",
            label: <Link to="/website/pages/admission">Admission</Link>,
          },
          {
            key: "44",
            label: <Link to="/website/pages/career">Career</Link>,
          },
          {
            key: "50",
            label: <Link to="/website/pages/notice">Notice</Link>,
          },
          {
            key: "48",
            label: <Link to="/website/pages/gallery">Gallery</Link>,
          },
          {
            key: "45",
            label: <Link to="/website/pages/contact-us">Contact Us</Link>,
          },
          {
            key: "51",
            label: (
              <Link to="/website/pages/principals-message">
                Principal's Message
              </Link>
            ),
          },
          {
            key: "571",
            label: <Link to="/website/pages/teachers">Teachers</Link>,
          },
          {
            key: "572",
            label: (
              <Link to="/website/pages/schools-policy">Schools Policy</Link>
            ),
          },
          {
            key: "573",
            label: (
              <Link to="/website/pages/rules-regulations">
                Rules & Regulations
              </Link>
            ),
          },
          {
            key: "574",
            label: <Link to="/website/pages/curriculums">Curriculums</Link>,
          },
          {
            key: "575",
            label: <Link to="/website/pages/class-routine">Class Routine</Link>,
          },
          {
            key: "576",
            label: (
              <Link to="/website/pages/academic-calendar">
                Academic Calendar
              </Link>
            ),
          },
          {
            key: "577",
            label: <Link to="/website/pages/events">Events</Link>,
          },
        ],
      },
    ],
  },
];

export const attendanceData = [
  { Month: "January", Attendance: 95, Class: "Class 1" },
  { Month: "February", Attendance: 93, Class: "Class 1" },
  { Month: "March", Attendance: 90, Class: "Class 1" },
  { Month: "April", Attendance: 92, Class: "Class 1" },
  { Month: "May", Attendance: 94, Class: "Class 1" },
  { Month: "June", Attendance: 96, Class: "Class 1" },
  { Month: "July", Attendance: 97, Class: "Class 1" },
  { Month: "August", Attendance: 95, Class: "Class 1" },
  { Month: "September", Attendance: 93, Class: "Class 1" },
  { Month: "October", Attendance: 92, Class: "Class 1" },
  { Month: "November", Attendance: 94, Class: "Class 1" },
  { Month: "December", Attendance: 96, Class: "Class 1" },

  // { Month: "January", Attendance: 88, Class: "Class 2" },
  // { Month: "February", Attendance: 87, Class: "Class 2" },
  // { Month: "March", Attendance: 85, Class: "Class 2" },
  // { Month: "April", Attendance: 86, Class: "Class 2" },
  // { Month: "May", Attendance: 89, Class: "Class 2" },
  // { Month: "June", Attendance: 90, Class: "Class 2" },
  // { Month: "July", Attendance: 91, Class: "Class 2" },
  // { Month: "August", Attendance: 89, Class: "Class 2" },
  // { Month: "September", Attendance: 88, Class: "Class 2" },
  // { Month: "October", Attendance: 87, Class: "Class 2" },
  // { Month: "November", Attendance: 89, Class: "Class 2" },
  // { Month: "December", Attendance: 90, Class: "Class 2" },

  // { Month: "January", Attendance: 92, Class: "Class 3" },
  // { Month: "February", Attendance: 91, Class: "Class 3" },
  // { Month: "March", Attendance: 90, Class: "Class 3" },
  // { Month: "April", Attendance: 89, Class: "Class 3" },
  // { Month: "May", Attendance: 91, Class: "Class 3" },
  // { Month: "June", Attendance: 93, Class: "Class 3" },
  // { Month: "July", Attendance: 94, Class: "Class 3" },
  // { Month: "August", Attendance: 92, Class: "Class 3" },
  // { Month: "September", Attendance: 91, Class: "Class 3" },
  // { Month: "October", Attendance: 90, Class: "Class 3" },
  // { Month: "November", Attendance: 92, Class: "Class 3" },
  // { Month: "December", Attendance: 93, Class: "Class 3" },
];
