import { Breadcrumb, Button, Image, Pagination, Table, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  renderDescription,
} from "../../../components/utility";
import { fetchTestimonials } from "../../../libs/redux/features/website/testimonialSlice";
import usePageTitle from "../../../components/PageTitle";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const { Search } = Input;

const Testimonials = () => {
  usePageTitle("Testimonials");

  const dispatch = useDispatch();
  const { isLoading, data, totalItems } = useSelector(
    (state) => state.testimonials
  );

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const offset = (currentPage - 1) * pageSize;
  useEffect(() => {
    dispatch(fetchTestimonials({ search: search, limit: pageSize, offset }));
  }, [dispatch, pageSize, offset, search]);

  const handleSearch = (value) => {
    setSearch(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Designation",
      dataIndex: "designation",
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      render: (text) => renderDescription(text),
    },

    {
      title: "Image",
      dataIndex: "image",
      render: (image) => (
        <div>
          <Image src={image.image} alt={image.alt_text} width={50} />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/website/components/testimonials/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `website/testimonial/${record.id}/`,
                fetchTestimonials
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: item.name || "N/A",
    Designation: item.designation || "N/A",
    Feedback: item.feedback || "N/A",
    Address: item.address || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Name",
      "Designation",
      "Feedback",
      "Address",
      "Status",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Testimonial Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Testimonial Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Testimonials</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: "Website" },
              { title: "Components" },
              { title: "Testimonials" },
            ]}
          />
        </div>
        <div>
          <Link to="/website/components/testimonials/add-new">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic">
            <Search
              placeholder="Search testimonials"
              enterButton
              className="w-200"
              onSearch={handleSearch}
            />
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data && data.length > 0 ? data : []}
          rowKey={(record) => record.id}
          pagination={false}
          loading={isLoading}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = (currentPage - 1) * pageSize + 1;
            const endItem = Math.min(currentPage * pageSize, totalItems);

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalItems}
                  onChange={handlePageChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Testimonials;
