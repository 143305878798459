import { Breadcrumb, Button, Checkbox, Col, DatePicker, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleAcademicYear,
  postAcademicYear,
  updateAcademicYear,
} from "../../../libs/redux/features/academic/academicYearSlice";
import usePageTitle from "../../../components/PageTitle";

const AcademicYearForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getSingleAcademicYear(id));
    }
  }, [id, dispatch]);

  const { isLoading, singleDataLoading, singleData } = useSelector(
    (state) => state.academicYear
  );

  usePageTitle(id ? "Academic Year Detail" : "Add Academic Year");

  const [academicYear, setAcademicYear] = useState({
    start_date: "",
    end_date: "",
    is_active: false,
  });

  useEffect(() => {
    if (Object.keys(singleData).length > 0) {
      setAcademicYear({
        start_date: singleData.start_date,
        end_date: singleData.end_date,
        is_active: singleData.is_active,
      });
    }
  }, [singleData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateAcademicYear({ id, Data: academicYear }));
    } else {
      dispatch(
        postAcademicYear({
          academicData: academicYear,
          onSuccess: () => {
            setAcademicYear({
              start_date: "",
              end_date: "",
              is_active: false,
            });
          },
        })
      );
    }
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between p-4">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? "Academic Year Detail" : "Add Academic Year"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: <Link to="/">Home</Link>,
              },
              {
                title: "Academic",
              },
              {
                title: (
                  <Link to="/academic/academic-years">Academic Years</Link>
                ),
              },
              {
                title: id ? "Academic Year Detail" : "Add Academic Year",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section p-4 bg-white rounded-10">
        <form onSubmit={handleSubmit}>
          {singleDataLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : (
            <Row className="responsive-row">
              <Col span={24}>
                <div className="mb-3">
                  <p className="mb-2 ps-2 fw-6">Start Date</p>
                  <DatePicker
                    className="w-100"
                    value={
                      academicYear.start_date
                        ? dayjs(academicYear.start_date)
                        : null
                    }
                    onChange={(e) =>
                      setAcademicYear((prev) => ({
                        ...prev,
                        start_date: e ? dayjs(e).format("YYYY-MM-DD") : "",
                      }))
                    }
                    required
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="mb-5">
                  <p className="mb-2 ps-2 fw-6">End Date</p>
                  <DatePicker
                    className="w-100"
                    value={
                      academicYear.end_date
                        ? dayjs(academicYear.end_date)
                        : null
                    }
                    onChange={(e) =>
                      setAcademicYear((prev) => ({
                        ...prev,
                        end_date: e ? dayjs(e).format("YYYY-MM-DD") : "",
                      }))
                    }
                    required
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="mb-5">
                  <Checkbox
                    checked={academicYear.is_active}
                    onChange={(e) =>
                      setAcademicYear((prev) => ({
                        ...prev,
                        is_active: e.target.checked,
                      }))
                    }
                  >
                    Is Active ?
                  </Checkbox>
                </div>
              </Col>
              <Col span={24}>
                <Button
                  className="w-100 mb-5"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {id ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          )}
        </form>
      </div>
    </div>
  );
};

export default AcademicYearForm;
